import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';

import ScoringResponsesFilters from './ScoringResponsesFilters';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class ScoringResponsesList extends React.Component {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE
  };

  componentDidMount() {
    this._fetchItems();
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchScoringList({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  render() {
    const {
      classes,
      scoringResponses = [],
      meta = {},
      requestSend
    } = this.props;

    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Resultados de Scoring'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <ScoringResponsesFilters onChangeFilter={this._fetchItems} />
          </div>
        </div>
        <div>
          <Table
            name={'Resultado'}
            columns={columns}
            data={mapData(scoringResponses)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={(meta && meta.total_count) || 0}
            loading={requestSend}
          />
        </div>
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

ScoringResponsesList.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(ScoringResponsesList);
