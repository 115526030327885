import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import App from './App';
import { setCurrentUser } from '../actions/authentication_actions';

export default class Root extends Component {
  componentDidMount() {
    this._persistStoreData();
  }
  // Load persisted data
  // FIXME should introduce redux-persist or some
  // other stable battle-tested solution instead.
  _persistStoreData() {
    const { store } = this.props;

    const maybeUserString = localStorage.getItem('user');
    if (!maybeUserString) {
      return;
    }

    // if the page is reloaded
    if (window.performance) {
      if (performance.navigation.type == 1) {
        return;
      }
    }

    try {
      const maybeUser = JSON.parse(maybeUserString);

      if (typeof maybeUser !== 'object' || maybeUser === null) {
        return;
      }

      store.dispatch(setCurrentUser(maybeUser));
    } catch (error) {
      // pass
    }
  }

  render() {
    const { store, history } = this.props;
    return (
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <App />
        </ConnectedRouter>
      </Provider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
