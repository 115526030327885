import range from 'lodash/range';

export const NAME_MATCHES_DC = 14; //Name matches in datacredito query
export const NAME_DOES_NOT_MATCH_DC = 10; //Name does not match in datacredito query

export const DC_STATUS = {
  '00': 'VIGENTE',
  '12': 'SUSPENDIDA',
  '21': 'FALLECIDO',
  '22': 'VALIDAR CON DATA',
  '29': 'CANCELADA',
  '77': 'REVISION',
  '99': 'EN TRAMITE'
};

export const ANI_STATUS = {
  '0': 'VIGENTE',
  '1': 'VIGENTE',
  '12': 'SUSPENDIDA',
  '21': 'FALLECIDO',
  '22': 'VALIDAR CON DATA',
  '29': 'CANCELADA',
  '77': 'REVISION',
  '99': 'EN TRAMITE'
};

export const DC_GENDERS = {
  '1': 'MUJER',
  '2': 'MUJER',
  '3': 'MUJER',
  '4': 'HOMBRE'
};

export const doctypes = [
  { id: 1, name: 'Cédula', title: 'Cédula', value: 'Cédula' },
  { id: 2, name: 'Pasaporte', title: 'Pasaporte', value: 'Pasaporte' },
  {
    id: 3,
    name: 'Cédula de Extranjería',
    title: 'Cédula de Extranjería',
    value: 'Cédula de Extranjería'
  },
  {
    id: 4,
    name: 'Tarjeta de Identidad',
    title: 'Tarjeta de Identidad',
    value: 'Tarjeta de Identidad'
  },
  { id: 5, name: 'Nit / Rut', title: 'Nit / Rut', value: 'Nit / Rut' }
];

export const relationships = [
  { id: 1, name: 'Padres', title: 'Padres', value: 'Padres' },
  { id: 2, name: 'Suegros', title: 'Suegros', value: 'Suegros' },
  { id: 3, name: 'Cuñados', title: 'Cuñados', value: 'Cuñados' },
  { id: 4, name: 'Hermanos', title: 'Hermanos', value: 'Hermanos' },
  { id: 5, name: 'Sobrinos', title: 'Sobrinos', value: 'Sobrinos' },
  { id: 6, name: 'Nietos', title: 'Nietos', value: 'Nietos' },
  { id: 7, name: 'Abuelos', title: 'Abuelos', value: 'Abuelos' },
  { id: 8, name: 'Padrastro', title: 'Padrastro', value: 'Padrastro' },
  { id: 9, name: 'Madrastra', title: 'Madrastra', value: 'Madrastra' },
  { id: 10, name: 'Hijastro', title: 'Hijastro', value: 'Hijastro' },
  { id: 11, name: 'Yerno Nuera', title: 'Yerno Nuera', value: 'Yerno Nuera' },
  { id: 12, name: 'Tío', title: 'Tío', value: 'Tío' },
  { id: 13, name: 'Primo', title: 'Primo', value: 'Primo' },
  { id: 14, name: 'Adicional', title: 'Adicional', value: 'Adicional' },
  { id: 15, name: 'Mascotas', title: 'Mascotas', value: 'Mascotas' }
];

export const maritalStatus = [
  { id: 1, name: 'SOLTERO(A)' },
  { id: 2, name: 'UNION LIBRE' },
  { id: 3, name: 'CASADO(A)' },
  { id: 4, name: 'DIVORCIADO(A)' },
  { id: 5, name: 'VIUDO(A)' },
  { id: 6, name: 'SEPARADO' }
];

export const gender = [
  { id: 1, name: 'Femenino' },
  { id: 4, name: 'Masculino' }
];

export const friendly_gender = [
  { id: 'F', name: 'Femenino' },
  { id: 'M', name: 'Masculino' }
];

export const streetType = [
  { id: 1, name: 'CL' },
  { id: 2, name: 'KR' },
  { id: 3, name: 'DG' },
  { id: 4, name: 'TV' },
  { id: 5, name: 'KM' }
];

export const addressComponent = Object.freeze({
  city: { pos: 0, name: 'city' },
  department: { pos: 1, name: 'department' },
  streetAddress: {
    pos: 2,
    name: 'streetAddress',
    info: {
      initialStreet: { pos: 0, name: 'initialStreet' },
      initialStreetNumber: {
        pos: 1,
        name: 'initialStreetNumber'
      },
      intersectingStreet: {
        pos: 2,
        name: 'intersectingStreet'
      },
      intersectingStreetNumber: {
        pos: 3,
        name: 'intersectingStreetNumber'
      },
      houseNumber: { pos: 4, name: 'houseNumber' }
    }
  },
  complement: { pos: 3, name: 'complement' }
});

export const houseType = [
  { id: 1, name: 'Propia' },
  { id: 2, name: 'Alquilada' },
  { id: 3, name: 'Familiar' },
  { id: 4, name: 'Parientes' },
  { id: 5, name: 'Amigos' }
];

export const bankAccountType = [
  { id: '', name: 'Ninguno' },
  { id: 1, name: 'Ahorros' },
  { id: 2, name: 'Corriente' }
];

export const subcategory = [
  { id: 1, name: 'ESTRATO 1' },
  { id: 2, name: 'ESTRATO 2' },
  { id: 3, name: 'ESTRATO 3' },
  { id: 4, name: 'ESTRATO 4' },
  { id: 5, name: 'ESTRATO 5' },
  { id: 6, name: 'ESTRATO 6' }
];

export function installments(min_installments, max_installments) {
  const installments = range(min_installments, max_installments + 1).map(
    num => ({
      id: num,
      name: num === 1 ? `${num} cuota` : `${num} cuotas`
    })
  );
  return installments;
}

export const studyLevels = [
  { id: 1, name: 'PRIMARIA' },
  { id: 2, name: 'BACHILLER' },
  { id: 3, name: 'TECNICO' },
  { id: 4, name: 'TECNOLOGO' },
  { id: 5, name: 'PROFESIONAL' },
  { id: 6, name: 'NINGUNO' },
  { id: 7, name: 'POSGRADO' },
  { id: 8, name: 'MAESTRIA' },
  { id: 9, name: 'DOCTORADO' },
  { id: 10, name: 'PREESCOLAR' },
  { id: 11, name: 'UNIVERSITARIO' }
];

export const occupations = [
  { id: 688, name: 'INDEPENDIENTE' },
  { id: 689, name: 'EMPLEADO' },
  { id: 690, name: 'AMA DE CASA' },
  { id: 691, name: 'ESTUDIANTE' },
  { id: 692, name: 'JUBILADO' },
  { id: 693, name: 'PENSIONADO' },
  { id: 694, name: 'DESEMPLEADO' }
];

export const QUOTA_TRANSFER_STATUS = {
  registered: 'registrada',
  reviewed: 'revisada',
  approved: 'Aprobada',
  revoked: 'Rechazada'
};

export const QUOTA_ACTION_OPTIONS = {
  registered: { inf: 'revisión', action: 'revisar' },
  reviewed: { inf: 'aprobación', action: 'Aprobar' },
  approved: { inf: 'cerrar', action: 'Cerrar' },
  revoked: { inf: 'cerrar', action: 'Cerrar' }
};

export const RETURN_STATUS = {
  registered: 'Registrada',
  reviewed: 'Revisada',
  approved: 'Aprobada',
  revoked: 'Rechazada'
};

export const PROMISSORY_STATUSES = {
  registered: 'Registrado',
  approved: 'Aprobado',
  rejected: 'Rechazado',
  canceled: 'Anulado',
  temporal: 'Temporal',
  exeption: 'Sin estado - Revise con un administrador'
};

export const MANUAL_QUOTA_STATUS = {
  registered: '0',
  reviewed: '1',
  approved: '2',
  revoked: '3',
  deactivated: '2',
};

export const MANUAL_QUOTA_VERBOSE_STATUS = {
  registered: 'Registrada',
  reviewed: 'Revisada',
  approved: 'Aprobada',
  revoked: 'Rechazada'
};

export const SINISTER_STATUS = ['Registrada', 'Aprobada', 'Rechazada'];

export const MANUAL_QUOTA_ACTIONS = [
  { inf: 'revisión', action: 'Revisar' },
  { inf: 'aprobación', action: 'aprobar' },
  { inf: 'desactivación', action: 'Desactivar' },
  { inf: 'cerrar', action: 'cerrar' },
  { inf: 'cerrar', action: 'cerrar' }
];

export const HOME_VISIT_STATUS = {
  0: 'Registrada',
  2: 'Aprobada',
  3: 'Rechazada'
};

export const POINT_USER_ASSOCIATION_STATUS = {
  registered: 'Registrada',
  approved: 'Aprobada',
  revoked: 'Rechazada'
};

export const POINT_OF_SALES_VALIDATION_METHOD = [
  { id: 1, name: 'Captor biométrico fijo' },
  { id: 2, name: 'Captor biométrico móvil' }
];

export const insuranceTypes = [
  { id: 1, name: 'Exequial', title: 'Exequial', value: 'funeral' }
];

export const DASHBOARD_COUNTER_SUMMARY_NAME = {
  ticket_promedio: 'Ticket promedio',
  cardif_delivery: 'CARDIF entregadas',
  regular_delivery: 'Tradicionales entregadas',
  // total_delivery: 'Total entregadas',
  cardif_pending_revision: 'CARDIF pendientes de revisión',
  regular_pending_revision: 'Tradicionales pendientes de revisión',
  // total_pending_revision: 'Total pendientes de revisión',
  cardif_pending_delivery: 'CARDIF pendientes de entrega',
  regular_pending_delivery: 'Tradicionales pendientes de entrega',
  // total_pending_delivery: 'Total pendientes de entrega',
  cardif_registered: 'CARDIF registradas',
  regular_registered: 'Tradicionales registradas',
  // total_registered: 'Total registradas',
  total_cardif_sales: 'Total CARDIF',
  total_regular_sales: 'Total tradicionales',
  total_sales: 'CARDIF + Tradicionales'
};

export const SALE_TYPES = [
  { id: 0, name: 'Tradicional' },
  { id: 1, name: 'Venta Desmaterializada' },
  { id: 2, name: 'Venta Digital' }
];

export const SEGMENTS = {
  1: 'GASODOMÉSTICOS ESPÉCIALES',
  2: 'FUTURO',
  3: 'NUEVO',
  4: 'INDECISO',
  5: 'ACTUAL',
  6: 'ROLLOVER',
  7: 'SIN CUPO'
};

export const SUBSEGMENTS_CODES = {
  SpecialGasAppliances: 1,
  NearFuture: 2,
  MidFuture: 3,
  DistantFuture: 4,
  New: 5,
  Undecided: 6,
  ActualWithDebt: 7,
  ActualWithoutDebt: 8,
  RolloverWithDebt: 9,
  RolloverWithoutDebt: 10,
  WithoutQuota: 11
};
export const COMPANY_SENIORITY_RANGES = [
  {
    id: 0,
    name: 'Menos de 1 año',
    title: 'Menos de 1 año',
    value: 'Menos de 1 año'
  },
  { id: 1, name: '1 año', title: '1 año', value: '1 año' },
  { id: 2, name: '2 años', title: '2 años', value: '2 años' },
  {
    id: 3,
    name: 'De 3 a 6 años',
    title: 'De 3 a 6 años',
    value: 'De 3 a 6 años'
  },
  {
    id: 4,
    name: 'Mas de 6 años',
    title: 'Mas de 6 años',
    value: 'Mas de 6 años'
  }
];

export const COMPANY_SIZES = [
  { title: 'Microempresa', value: 1 },
  { title: 'Pequeña', value: 2 },
  { title: 'Mediana', value: 3 },
  { title: 'Gran Empresa', value: 4 }
];

export const SUPPLIER_PROPOSAL_DOCUMENT_TYPES = {
  LastYearFinancialStatement: 1,
  PenultimateYearFinancialStatement: 2,
  CommercialBrochure: 3,
  AdditionalInCompanySalesStep: 4,
  LegalRepresentativeIdentification: 5,
  Rut: 6,
  ChamberOfCommerce: 7,
  AdditionalInSupplierDataStep: 11,
  Minute: 12,
  AdditionalInMinuteStep: 13,
  ConfigurationTemplates: 14,
  brandCertificate: 15,
  bankCertificate: 16,
  personalDataPolicy: 17,
  businessLogo: 18,
  supplierRegisterFile: 19,
  thirdLevelShareholderComposition: 20
};

export const SUPPLIER_PROPOSAL_MINUTE_TYPES = [
  { id: 1, name: 'Salud oral' },
  { id: 2, name: 'Grandes superficies - Motos' },
  { id: 3, name: 'Educación' },
  { id: 4, name: 'Canal tradicional' },
  { id: 5, name: 'Cargar otro tipo de minuta' }
];

export const SUPPLIER_PROPOSAL_SIGNER_TYPES = [
  { id: 1, name: 'Firmante' },
  { id: 2, name: 'Revisor' }
];

export const SUPPLIER_PROPOSAL_REJECTION_MOTIVES = {
  NoAgreement: 1,
  Voluntary: 2,
  LackOfSupplierCreation: 3,
  Other: 4,
  ProposalNotAccepted: 5
};

export const SUPPLIER_PROPOSAL_REJECTION_MOTIVE_DROPDOWN_OPTIONS = [
  {
    id: SUPPLIER_PROPOSAL_REJECTION_MOTIVES.NoAgreement,
    name: 'Cancelación por no acuerdo comercial'
  },
  // {
  //   id: SUPPLIER_PROPOSAL_REJECTION_MOTIVES.Voluntary,
  //   name: 'Cancelación voluntaria'
  // },
  {
    id: SUPPLIER_PROPOSAL_REJECTION_MOTIVES.ProposalNotAccepted,
    name: 'No aceptación de propuesta'
  },
  {
    id: SUPPLIER_PROPOSAL_REJECTION_MOTIVES.LackOfSupplierCreation,
    name: 'Cancelación por falta de creación de proveedor'
  },
  {
    id: SUPPLIER_PROPOSAL_REJECTION_MOTIVES.Other,
    name: 'Otro'
  }
];

export const PoliciesCode = {
  ActiveGas: 23,
  GasInstallationDate: 24,
  Punishment: 31,
  ArrearsInOtherService: 32,
  Refinancings: 34,
  Fraud: 36,
  PropertyOwnerShipDate: 39,
  refinancingBalance: 42,
  ArrearInGasProduct: 54,
  ArrearInBrillaProduct: 57,
  Suspensions: 80,
  NetworkBalance: 81
};

export const FriendlyClassName = {
  ManualQuotum: 'Cupo Manual',
  SaleReturnArticlesMassive: 'Devolución de Artículos',
  SaleGracePeriodMassive: 'Periodos de Gracia',
};

export const FriendlyBulkProcessStatus = {
  '0': 'Registrado',
  '1': 'En Proceso',
  '2': 'Finalizado',
  '3': 'Cancelado',
  '4': 'Finalizado_Con_Errores'
};

export const BulkProcessStatus = {
  Registered: 0,
  Processing: 1,
  Done: 2,
  Canceled: 3,
  DoneWithErrors: 4
};

export const PdfTypeV2 = {
  voucher: 'voucher',
  promissory: 'promissory'
}
