import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import _find from 'lodash/find';
import _compact from 'lodash/compact';
import Button from '@material-ui/core/Button';

import LoadingIndicator from '../../shared/LoadingIndicator';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

import combineStyles from '../../../helpers/combineStyles';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import ProfileStyles from './__styles__/Profile.styles';

export const Profile = ({
  classes,
  posInfo,
  isLoading,
  canUpdatePOS,
  canUpdatePOSLocationInfo,
  onEditPOS,
  cityName
}) => {
  return (
    <FormItemContainer
      title={'Información del Punto de Venta'}
      secondaryAction={
        !isLoading && (canUpdatePOS || canUpdatePOSLocationInfo) ? (
          <Button
            size={'small'}
            color={'secondary'}
            className={classnames(classes.smallButton, classes.actionButtons)}
            classes={{
              label: classes.buttonLabel
            }}
            onClick={onEditPOS}
          >
            {'Editar'}
          </Button>
        ) : null
      }
    >
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className={classes.root}>
          <div className={classes.details}>
            <Grid container spacing={16} className={classes.contactData}>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>
                  {'Nombre del punto de venta:'}
                </p>
                <p className={classes.body2}>{posInfo.description || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Estado:'}</p>
                <p className={classes.body2}>
                  {posInfo.active ? 'ACTIVO' : 'INACTIVO'}
                </p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Ciudad:'}</p>
                <p className={classes.body2}>{cityName}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Dirección:'}</p>
                <p className={classes.body2}>{posInfo.address}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Preguntas Reto:'}</p>
                <p className={classes.body2}>{posInfo.hasSecurityQuestions ? "Sí" : "No"}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Agendamiento:'}</p>
                <p className={classes.body2}>{posInfo.schedulable ? "Sí" : "No"}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Crosscore:'}</p>
                <p className={classes.body2}>{posInfo.canUseCrosscore ? "Sí" : "No"}</p>
              </Grid>
              <Grid item lg={6} className={classes.contactData}>
                <p className={classnames(classes.subtitle3)}>
                  Canal(es) de venta:
                </p>
                {posInfo.saleChannels.map(sc => {
                  return (<span className={classes.tag} key={sc.id}>
                    {sc.name}
                  </span>);
                })}
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Biometría dactilar:'}</p>
                <p className={classes.body2}>{posInfo.hasFingerprintBiometric ? "Sí" : "No"}</p>
              </Grid>
            </Grid>
          </div>
          <Divider
            variant={'middle'}
            light
            classes={{ root: classes.divider }}
          />
          <div className={classes.details}>
            <p className={classnames(classes.headline7, classes.detailsTitle)}>
              Contacto
            </p>
            <Grid container spacing={16} className={classes.contactData}>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Nombre del contacto:'}</p>
                <p className={classes.body2}>{posInfo.contactPerson || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Correo:'}</p>
                <p className={classes.body2}>{posInfo.email || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Teléfono:'}</p>
                <p className={classes.body2}>{posInfo.phone || '--'}</p>
              </Grid>
              <Grid item lg={6}>
                <p className={classes.subtitle3}>{'Celular:'}</p>
                <p className={classes.body2}>{posInfo.mobile || '--'}</p>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </FormItemContainer>
  );
};

const styles = theme => ({
  tag: {
    margin: 0,
    marginRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    fontSize: '14px',
    display: 'inline-block',
    padding: [[theme.spacing.unit * 0.5, theme.spacing.unit * 1]],
    borderRadius: '18px',
    backgroundColor: 'rgba(254,176,11,0.12)',
    color: '#FEB00B',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    '&:last-child': {
      marginRight: 0
    }
  }
});

export default withStyles(
  combineStyles(ProfileStyles, ButtonStyles, TypographyStyles, styles)
)(Profile);
