import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import HomeVisitFilters from './HomeVisitFilters';
import HomeVisitRevision from './Modal/HomeVisitRevision';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';
import { history } from '../../../helpers/history';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';
import _get from 'lodash/get';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import ListsStyle from '../__styles__/Lists.style';

import RequestService from '../../../services/request_service';
const requestService = new RequestService();

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class HomeVisitList extends React.PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    revisionModal: false,
    homeVisitInModal: {}
  };

  componentDidMount() {
    this.fetchItems();
    !this.props.causals && this.props.fetchOrgData();
    this.props.fetchPointsOfSaleHomeVisits();
    this.checkLocationState();
  }

  componentDidUpdate(prevProps) {
    this.checkLocationState();
    if (
      prevProps.homeVisitUpdated !== this.props.homeVisitUpdated &&
      this.props.homeVisitUpdated
    ) {
      this._handleCloseRevisionModal();
    }
  }

  checkLocationState = async () => {
    const {
      location: { state }
    } = this.props;
    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const homeVisit = await this._fetchItem(state.entityId);
        const mappedHomeVisit = mapData(homeVisit);
        this._handleOpenRevisionModal(mappedHomeVisit[0]);
      }
    }
  };

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchHomeVisits({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _fetchItem = async id => {
    try {
      const payload = await requestService.get(
        'schedules',
        { id_eq: id },
        true
      );
      return payload.data;
    } catch (errors) {
      return null;
    }
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleOpenRevisionModal = homeVisit => {
    const id = _get(homeVisit, 'id');
    this.props.getHomeVisitArticles(id);
    this.setState({ homeVisitInModal: homeVisit, revisionModal: true });
  };

  _handleCloseRevisionModal = () => {
    this.setState({ homeVisitInModal: {}, revisionModal: false });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };

  _handleUpdateHomeVisit = homeVisit => {
    const query = parseQuerystring(this.props) || {};
    this.props.homeVisitRevision(homeVisit, {
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleFilterChange = filters => {
    this.fetchItems( filters );
  };

  excelReportDownloadEnabled = filters => {
    return (
      this.props.canExportSchedulesToExcel &&
      this.dateFilterHasValidRange(filters)
    );
  };

  dateFilterHasValidRange = filters => {
    const dateRange = this.getDateFilterRange(filters);
    if (_isEmpty(dateRange)) return false;
    const dateDiff = new Date(dateRange.end) - new Date(dateRange.start);
    const dateDiffInMonths = dateDiff / 1000 / 3600 / 24 / 31;
    return dateDiffInMonths < 1;
  };

  getDateFilterRange = filters => {
    const dateFilterValue = filters.date_range;
    if (!dateFilterValue) return {};
    const splittedDateFilterValue = dateFilterValue.split(' - ');
    const dateRange = {};
    dateRange.start = splittedDateFilterValue[0];
    dateRange.end = splittedDateFilterValue[1] || splittedDateFilterValue[0];
    return dateRange;
  };

  excelReportDownloadButtonIsVisible = () => {
    const filters = parseQuerystring(this.props) || {};
    return (
      this.excelReportDownloadEnabled(filters) &&
      !_isEmpty(this.props.homeVisits)
    );
  };

  fetchIndexExcelReportFile = () => {
    const filters = parseQuerystring(this.props) || {};
    delete filters.perPage;
    this.props.fetchIndexExcelReportFile(filters);
  };

  render() {
    const {
      homeVisits = [],
      meta,
      causals = [],
      classes,
      requestSend = true,
      causalsLoading,
      pointsOfSale = [],
      fetchingExcelReportFile,
      saleChannels = [],
      homeVisitArticles = [],
      isProcessScheduleInOsfActive
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Legalización de Agendamientos'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <HomeVisitFilters
              onChangeFilter={this._handleFilterChange}
              pointsOfSale={pointsOfSale}
              saleChannels={saleChannels}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Agendamiento'}
            columns={columns}
            data={mapData(homeVisits)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleOpenRevisionModal(props)}
            totalCount={meta.totalCount || 0}
            loading={requestSend || causalsLoading}
            withDownload={this.excelReportDownloadButtonIsVisible()}
            onDownloadIconClick={this.fetchIndexExcelReportFile}
            loadingDownload={fetchingExcelReportFile}
          />
        </div>
        {this.state.revisionModal && (
          <HomeVisitRevision
            homeVisit={this.state.homeVisitInModal}
            homeVisitArticles={homeVisitArticles}
            openUpdateScheduleModal={this.props.openUpdateScheduleModal}
            onSave={this._handleUpdateHomeVisit}
            onClose={this._handleCloseRevisionModal}
            causals={causals}
            requestSend={this.props.updateHomeVisitSend}
            canUpdateHomeVisits={this.props.canUpdateHomeVisits}
            canLegalizeHomeVisits={this.props.canLegalizeHomeVisits}
            canShowHomeVisit={this.props.canShowHomeVisit}
            serverErrors={this.props.homeVisitRevisionErrors}
            isProcessScheduleInOsfActive={isProcessScheduleInOsfActive}
          />
        )}
      </div>
    );
  }
}

HomeVisitList.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchHomeVisits: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(HomeVisitList);
