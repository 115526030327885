import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UserDetail from './UserDetail';
import {
  fetchUserInfo,
  updateUser,
  updateUserPictureProfile,
  updateAdditionalInfo,
  updatePersonalData,
  updateMedia,
  updatePassword,
  confirmUpdateMedia,
  deletePictureProfile,
  updatePointOfSaleOfUsers,
  resendConfirmationEmail
} from '../../../actions/user_actions';
import { sendChangePasswordByAdmin, sendResetPassword } from '../../../actions/session_actions';
import { newAlert } from '../../../actions/alert_actions';
import { fetchAllRoles, fetchRoles } from '../../../actions/role_actions';
import { fetchPointsOfSaleNamesAssociation } from '../../../actions/points_of_sale_actions';
import { fetchOrgData } from '../../../actions/global_setting_actions';

function mapStateToProps(state) {
  return {
    permissions: state.authentication.permissions,
    currentUser: state.authentication.currentUser,
    userInfo: state.user.userInfo,
    coordinatorRoles: state.globalSetting.orgData.coordinador_aliado_roles,
    salesmanRoles: state.globalSetting.orgData.salesman_roles,
    adminRoles: state.globalSetting.orgData.admin_roles,
    suppliers: state.globalSetting.orgData.suppliers,
    rolesList: state.role.rolesList,
    roles: state.role.roles,
    pointsOfSales: state.pointOfSale.pointsOfSaleNamesAssociation,
    canListSalesmanRoles: state.authentication.currentUser.canListSalesmanRoles,
    requestUserDetailSend: state.requestState.requestUserDetailSend,
    requestPointOfSalesSend: state.requestState.requestPointOfSalesSend,
    requestUpdatePointOfSalesOfUserSend:
      state.requestState.requestUpdatePointOfSalesOfUserSend,
    requestUpdateAdditionalInfoSend:
      state.requestState.requestUpdateAdditionalInfoSend,
    requestUpdatePersonalDataSend:
      state.requestState.requestUpdatePersonalDataSend,
    requestUpdateUserPictureProfileSend:
      state.requestState.requestUpdateUserPictureProfileSend,
    requestDeletePictureProfileSend:
      state.requestState.requestDeletePictureProfileSend,
    requestRolesSend: state.requestState.requestRolesSend,
    requestResendConfirmationEmailSend:
      state.requestState.requestResendConfirmationEmailSend,
    requestActivateUserToggleSend:
      state.requestState.requestActivateUserToggleSend,
    requestUpdateRolesSend: state.requestState.requestUpdateRolesSend,
    requestChangePasswordSend: state.requestState.requestUpdatePasswordSend,
    requestUpdateUserSend: state.requestState.requestUpdateUserSend,
    requestErrorsOnUpdateUser: state.requestState.requestErrorsOnUpdateUser,
    requestErrorsOnUpdateMedia: state.requestState.requestErrorsOnUpdateMedia,
    requestErrorsOnUpdatePersonalData:
      state.requestState.requestErrorsOnUpdatePersonalData,
    requestNewOtpSend: state.requestState.requestNewOtpSend,
    osfMaxIdLength: state.globalSetting.orgData.osf_max_id_length,
    hugeSurfaceCoordinatorRoles:
      state.globalSetting.orgData.huge_surface_coordinator_roles,
    companyName: state.globalSetting.orgData.company_name,
    isBlackListActive: state.globalSetting.orgData.get_blacklist_active,
    maritalStatus: state.globalSetting.orgData.get_marital_statuses
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchUserInfo: bindActionCreators(fetchUserInfo, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    updateUser: bindActionCreators(updateUser, dispatch),
    updateUserPictureProfile: bindActionCreators(
      updateUserPictureProfile,
      dispatch
    ),
    resendConfirmationEmail: bindActionCreators(
      resendConfirmationEmail,
      dispatch
    ),
    deletePictureProfile: bindActionCreators(deletePictureProfile, dispatch),
    updateAdditionalInfo: bindActionCreators(updateAdditionalInfo, dispatch),
    updatePersonalData: bindActionCreators(updatePersonalData, dispatch),
    updatePointOfSaleOfUsers: bindActionCreators(
      updatePointOfSaleOfUsers,
      dispatch
    ),
    updatePassword: bindActionCreators(updatePassword, dispatch),
    fetchOrgData: bindActionCreators(fetchOrgData, dispatch),
    fetchAllRoles: bindActionCreators(fetchAllRoles, dispatch),
    fetchRoles: bindActionCreators(fetchRoles, dispatch),
    fetchPointsOfSaleNamesAssociation: bindActionCreators(
      fetchPointsOfSaleNamesAssociation,
      dispatch
    ),
    updateMedia: bindActionCreators(updateMedia, dispatch),
    confirmUpdateMedia: bindActionCreators(confirmUpdateMedia, dispatch),
    resendConfirmationEmail: bindActionCreators(
      resendConfirmationEmail,
      dispatch
    ),
    sendResetPassword: bindActionCreators(sendResetPassword, dispatch),
    sendChangePasswordByAdmin: bindActionCreators(sendChangePasswordByAdmin, dispatch),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserDetail);
