import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import classnames from 'classnames';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import Button from '@material-ui/core/Button';
import FormModal from './modals/FormModal';

const _getNewConfig = () => {
  return {
    categoryName: '',
    geographicLocationName: '',
    supplierName: '',
    categoryId: undefined,
    geographicLocationId: undefined,
    supplierId: undefined
  };
}

class ScoringConfigurationList extends React.PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    currentConfig: _getNewConfig()
  }

  componentDidMount() {
    this._fetchData();
  }

  _fetchData(params = {}) {
    this._fetchConfigs(params);
  }

  _fetchConfigs = (params = {}) => {
    const { meta } = this.props;
    const page = params.page || (meta && meta.currentPage) || 1;
    this.props.fetchSchedulePointOfSaleConfigurationList({
      page: page,
      perPage: this.state.rowsPerPage
    });
  }

  _handleChangePage = page => {
    this._fetchConfigs({
      page: page + 1
    });
  }

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchConfigs);
  }

  _formButtonIsVisible = () => {
    return this.props.canCreateScoringExclusions;
  }

  _getTableName = () => {
    const { configs = [] } = this.props;
    return configs.length != 1
      ? 'Exclusiones'
      : 'Exclusión';
  }

  _openModal = (params = {}) => {
    this.props.openFormModal();
    this.setState({
      currentConfig: this._getCurrentConfig(params.config)
    });
  }

  _closeModal = () => {
    this.props.closeFormModal();
  }

  _isLoadingData = () => {
    const {
      loading = false
    } = this.props;
    return loading;
  }

  _getNewConfig = () => {
    return {
      categoryName: '',
      geographicLocationName: '',
      supplierName: '',
      categoryId: undefined,
      geographicLocationId: undefined,
      supplierId: undefined
    };
  }

  _getCurrentConfig = config => {
    return config || _getNewConfig();
  }

  _handleRowClick = config => {
    if (this.props.canUpdateScoringExclusions) {
      this._openModal({ config });
    }
  }

  render() {
    const {
      configs = [],
      meta = {},
      classes,
      categories,
      geographicLocations,
      suppliers,
      modalSubmitLoading = false,
      modalDestroyLoading = false,
      createConfig,
      updateConfig,
      formModalIsOpen,
      deleteConfig,
      canDestroyScoringExclusions
    } = this.props;

    const { currentPage } = meta;

    const { rowsPerPage } = this.state;

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Exclusiones de Scoring'}
          </h1>
          {this._formButtonIsVisible() && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={() => this._openModal()}
            >
              {'Crear Exclusión'}
            </Button>
          )}
        </div>
        <div>
          <Table
            name={this._getTableName()}
            columns={columns}
            data={mapData(configs)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={currentPage - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={(meta && meta.totalCount) || 0}
            loading={this._isLoadingData()}
            onClickOnRow={this._handleRowClick}
          />
        </div>
        <div>
          {
            formModalIsOpen && (
              <FormModal
                onClose={this._closeModal}
                onDelete={(params) => {
                  const { meta } = this.props;
                  const page = params.page || (meta && meta.currentPage) || 1;
                  deleteConfig({
                    ...params,
                    page: page,
                    perPage: this.state.rowsPerPage
                  });
                }}
                config={this.state.currentConfig}
                categories={categories.filter(category => category.active)}
                suppliers={suppliers.filter(supplier => supplier.active)}
                geographicLocations={geographicLocations}
                createConfig={createConfig}
                updateConfig={updateConfig}
                submitLoading={modalSubmitLoading}
                modalDestroyLoading={modalDestroyLoading}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
                canDestroyScoringExclusions={canDestroyScoringExclusions}
              />
            )
          }
        </div>
      </div>
    );
  }
}

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(ScoringConfigurationList);