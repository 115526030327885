/* eslint-disable complexity */
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import PortalPromissoryPage from './PortalPromissoryPage';
import { setCurrentStep } from '../../../actions/stepper_actions';
import { fetchLocationNames } from '../../../actions/financial_plan_actions';
import { newAlert } from '../../../actions/alert_actions';
import {
  registerSale,
  registerPromissory,
  fetchProfessions
} from '../../../actions/portal_financing_actions';
import { sendOtp, resetOtpInfoResponse, validateOtp, validateUniqueMobile } from '../../../actions/otp_validation_actions';
import { doctypes } from '../../../constants/enums';
import moment from 'moment-timezone';
import _get from 'lodash/get';
import { removeFirstZeros } from '../../../helpers/utilityFunctions';
import _isEmpty from 'lodash/isEmpty';
import { fetchPointOfSaleInfo } from '../../../actions/points_of_sale_actions';

function mapStateToProps(state) {
  let debtorLastName = '';
  let cosignerLastName = '';
  const pf = state.portalFinancing;
  const pp = state.portalFinancing.promissory;
  const sc = pf.supplier || {};
  const neighborhood = _get(pf, 'neighborhood', null);
  let data = {};
  const studyLevels = state.globalSetting.orgData.get_levels_of_studies;
  if (pf.promissory && !pf.moveQuotaData.contract) {
    const debtor = _get(pf, 'promissory.debtor');
    const cosigner = _get(pf, 'promissory.cosigner');
    data = {
      debtor: {
        newName: pf.id_name !== '--' ? pf.id_name : '',
        newLastname: pf.id_first_last_name === '--' ? '' : pf.id_second_last_name === '--' ? pf.id_first_last_name : pf.id_first_last_name + ' ' + pf.id_second_last_name,
        name: debtor.name,
        last_name: debtor.last_name,
        marital_status: debtor.marital_status,
        debtor_doctype_id:
          debtor.type_of_identification &&
          doctypes.find(o => o.name === debtor.type_of_identification).id,
        type_of_identification: debtor.type_of_identification,
        identification: removeFirstZeros(debtor.identification),
        gender: debtor.gender === 'M' ? 4 : debtor.gender === 'F' ? 1 : 9,
        level_of_studies:
          debtor.level_of_studies &&
          studyLevels.find(o => o.name === debtor.level_of_studies).id,
        email: debtor.email,
        phone: debtor.phone,
        mobile: debtor.mobile,
        debtorNeighbourhood: _get(pf, 'neighborhood.description', ''),
        debtorNeighbourhoodId: _get(pf, 'neighborhood.id', ''),
        address: _get(pf, 'address', 'address.body', ''),
        type_of_house: debtor.type_of_house,
        profession: debtor.profession || '',
        occupation: debtor.occupation || '',
        company_name: debtor.company_name || '',
        date_of_birth: pf.debtor_birthdate,
        birth_place: pf.debtor_birth_place
      },
      cosigner: cosigner
        ? {
          contract_id: pf.cosigner_contract,
          name: _get(cosigner, 'name', ''),
          last_name: _get(cosigner, 'last_name', ''),
          marital_status: _get(cosigner, 'marital_status', ''),
          cosigner_doctype_id: _get(cosigner, 'type_of_identification')
            ? doctypes.find(o => o.name === cosigner.type_of_identification)
              .id
            : '',
          type_of_identification: _get(
            cosigner,
            'type_of_identification',
            ''
          ),
          identification: _get(cosigner, 'identification', ''),
          gender:
            cosigner.gender === 'M' ? 4 : cosigner.gender === 'F' ? 1 : 9,
          level_of_studies: _get(cosigner, 'level_of_studies')
            ? studyLevels.find(o => o.name === cosigner.level_of_studies).id
            : '',
          email: _get(cosigner, 'email', ''),
          phone: _get(cosigner, 'phone', ''),
          mobile: _get(cosigner, 'mobile', ''),
          cosignerNeighbourhood: _get(pf.cosignerContractData, 'neighborhood.description', ''),
          cosignerNeighbourhoodId: _get(pf.cosignerContractData, 'neighborhood.id', ''),
          address: _get(pf.cosignerContractData, 'address', ''),
          type_of_house: _get(cosigner, 'type_of_house', ''),
          profession: _get(cosigner, 'profession', ''),
          occupation: cosigner.occupation || '',
          company_name: cosigner.company_name,
          date_of_birth: pf.cosigner_birthdate,
          stratum: _get(pf.cosignerContractData, 'stratum', '')
        }
        : null
    };
  } else {
    if (!pf.client_debtor_last_name || !pf.client_debtor_second_last_name) {
      debtorLastName = pf.last_name;
    } else {
      debtorLastName = pf.client_debtor_last_name + ' ' + pf.client_debtor_second_last_name;
    }

    if (pf.cosigner_identification) {
      if (!pf.client_cosigner_last_name || !pf.client_cosigner_second_last_name) {
        cosignerLastName = _get(pf.cosignerContractData, 'lastName', '');
      } else {
        cosignerLastName = pf.client_cosigner_last_name + ' ' + pf.client_cosigner_second_last_name;
      }
    }

    data = {
      debtor: {
        newName: pf.id_name !== '--' ? pf.id_name : '',
        newLastname: pf.id_first_last_name === '--' || !pf.id_first_last_name ? '' : pf.id_second_last_name === '--' || !pf.id_second_last_name ? pf.id_first_last_name : pf.id_first_last_name + ' ' + pf.id_second_last_name,
        name: pf.client_debtor_name || pf.name,
        last_name: debtorLastName,
        debtor_doctype_id:
          pf.debtor_identification_type &&
          doctypes.find(o => o.name === pf.debtor_identification_type).id,
        type_of_identification: pf.debtor_identification_type,
        identification: pf.debtor_identification,
        address: pf.address,
        date_of_birth: pf.debtor_birthdate,
        debtorNeighbourhood: _get(pf, 'neighborhood.description', ''),
        debtorNeighbourhoodId: _get(pf, 'neighborhood.id', ''),
        locality: _get(pf, 'locality', ''),
        department: _get(pf, 'department', ''),
        contract_id: pf.debtor_contract_number
      },
      cosigner: pf.cosigner_identification
        ? {
          contract_id: pf.cosigner_contract_number,
          identification: pf.cosigner_identification,
          cosigner_doctype_id:
            pf.cosigner_identification_type &&
            doctypes.find(o => o.name === pf.cosigner_identification_type).id,
          type_of_identification: pf.cosigner_identification_type,
          cosignerDoctypeText: pf.cosigner_identification_type,
          date_of_birth: pf.cosigner_birthdate,
          name:
            pf.client_cosigner_name ||
            _get(pf.cosignerContractData, 'name', '') ||
            pf.dc_cosigner_name,
          last_name: cosignerLastName || pf.cosigner_identification_type == 'Cédula de Extranjería' ? pf.dc_cosigner_first_last_name : '',
          address: _get(pf.cosignerContractData, 'address', ''),
          stratum: _get(pf.cosignerContractData, 'stratum', ''),
          cosignerNeighbourhood: _get(pf.cosignerContractData, 'neighborhood.description', ''),
          cosignerNeighbourhoodId: _get(pf.cosignerContractData, 'neighborhood.id', ''),
        }
        : null
    };
  }
  data.debtor.id_expedition_date = getDebtorIdExpeditionDate(state);
  data.debtor.id_expedition_place = getDebtorIdExpeditionPlace(state);
  if (pf.cosigner_identification) {
    data.cosigner.id_expedition_date =  getCosignerIdExpeditionDate(state);
    data.cosigner.id_expedition_place = getCosignerIdExpeditionPlace(state);
  }
  return {
    ...state.portalFinancing.validatedSaleDetails,
    ...data,
    megaQuotaInfo: pf.megaQuotaInfo,
    isSecureSale: pf.isSecureSale,
    first_invoice: pf.invoice1,
    second_invoice: pf.invoice2,
    invoiceValidationSkipped: pf.invoiceValidationSkipped,
    process: state.stepper.process,
    nextStep: state.stepper.nextStep,
    portalNext: state.stepper.portalNext,
    promissoryNext: state.stepper.promissoryNext,
    locationNames: state.financialPlans.locationNames,
    neighbourhoodNames: state.financialPlans.neighbourhoodNames,
    isInvoiceHolder: pf.holder,
    requiresCosigner: pf.require_cosigner,
    promissory: pp && pf.promissory,
    uniquePromissory: pp && pf.promissory.code,
    promissoryType: sc.allowed_promissory_type || (pp && pf.promissory.type),
    debtorContract: pf.debtor_contract_number,
    cosignerContract: pf.cosigner_contract_number,
    cosignerContractData: pf.cosignerContractData,
    debtorNeighborhood: neighborhood,
    debtorStratum: pf.stratum,
    saleChannel: pf.sale_channel,
    professions: pf.professions,
    isTransfer: !!pf.moveQuotaData.contract,
    routes: state.stepper.routes,
    applyScoring: pf.applyScoring,
    studyLevels: studyLevels,
    maritalStatus: state.globalSetting.orgData.get_marital_statuses,
    houseType: state.globalSetting.orgData.get_types_of_houses,
    companyName: state.globalSetting.orgData.company_name,
    requestOtpSend: state.requestState.requestOtpSend,
    requestValidateOtpSend: state.requestState.requestValidateOtpSend,
    otpSent: state.otpValidation.validationSendResponse,
    mobileValidated: state.otpValidation.validationConfirmationResponse,
    otpMobileConfirmedAt: state.otpValidation.mobileConfirmedAt,
    canValidateMobileOnSale: state.authentication.currentUser.supplier.canValidateMobileOnSale,
    isMobileValidationOnSaleActive: state.globalSetting.orgData.is_mobile_validation_on_sale_active,
    termsAndConditionsUrl: state.globalSetting.orgData.terms_and_conditions_url,
    isMobileValidationOnSecureSaleActive: state.globalSetting.orgData.is_mobile_validation_on_secure_sale_active,
    uniqueMobileValidation: state.otpValidation.validationUniqueMobileResponse,
    scoringQuota: pf.scoringQuota,
    posId: pf.point_of_sale,
    b2bSelectedPos: state.globalSetting.orgData.b2b_selected_pos,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    fetchLocationNames: bindActionCreators(fetchLocationNames, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    registerSale: bindActionCreators(registerSale, dispatch),
    registerPromissory: bindActionCreators(registerPromissory, dispatch),
    fetchProfessions: bindActionCreators(fetchProfessions, dispatch),
    sendOtp: bindActionCreators(sendOtp, dispatch),
    validateOtp: bindActionCreators(validateOtp, dispatch),
    resetOtpInfoResponse: bindActionCreators(resetOtpInfoResponse, dispatch),
    validateUniqueMobile: bindActionCreators(validateUniqueMobile, dispatch),
    fetchPointOfSaleInfo: bindActionCreators(fetchPointOfSaleInfo, dispatch),
  };
}

function getDebtorIdExpeditionDate(state) {
  const pf = state.portalFinancing;
  if (pf.debtor_identification_type == 'Cédula de Extranjería') {
    return pf.id_expedition_date
  }

  const expeditionDate =
    getDebtorIdExpeditionDateFromPromissory(state) ||
    getDebtorIdExpeditionDateFromDataCredito(state);
  const formattedExpeditionDate = formatExpeditionDate(expeditionDate);
  return formattedExpeditionDate;
}

function getCosignerIdExpeditionDate(state) {
  const pf = state.portalFinancing;
  if (pf.cosigner_identification_type == 'Cédula de Extranjería') {
    return pf.dc_cosigner_expedition_date
  }

  const expeditionDate =
    getCosignerIdExpeditionDateFromPromissory(state) ||
    getCosignerIdExpeditionDateFromDataCredito(state);
  const formattedExpeditionDate = formatExpeditionDate(expeditionDate);
  return formattedExpeditionDate;
}

function getDebtorIdExpeditionDateFromDataCredito(state) {
  return state.portalFinancing.id_debtor_expedition_date;
}

function getCosignerIdExpeditionDateFromDataCredito(state) {
  return state.portalFinancing.id_cosigner_expedition_date;
}

function getDebtorIdExpeditionDateFromPromissory(state) {
  const pf = state.portalFinancing;
  const debtor = _get(pf, 'promissory.debtor');
  return (
    pf.promissory && !pf.moveQuotaData.contract && debtor.id_expedition_date
  );
}

function getCosignerIdExpeditionDateFromPromissory(state) {
  const pf = state.portalFinancing;
  const cosigner = _get(pf, 'promissory.cosigner');
  return (
    pf.promissory &&
    !pf.moveQuotaData.contract &&
    _get(cosigner, 'id_expedition_date', null)
  );
}

function formatExpeditionDate(date) {
  if (!date) return null;
  return moment(date, 'YYYY-MM-DD').format('DD-MM-YYYY');
}

function getDebtorIdExpeditionPlace(state) {
  const pf = state.portalFinancing;
  if (pf.debtor_identification_type == 'Cédula de Extranjería') {
    return pf.id_country
  }
  return (
    getDebtorIdExpeditionPlaceFromPromissory(state) ||
    getDebtorIdExpeditionPlaceFromANI(state)
  );
}

function getDebtorIdExpeditionPlaceFromANI(state) {
  const pf = state.portalFinancing;
  return pf.id_debtor_expedition_place || null;
}

function getDebtorIdExpeditionPlaceFromPromissory(state) {
  const pf = state.portalFinancing;
  const debtor = _get(pf, 'promissory.debtor');
  return (
    pf.promissory && !pf.moveQuotaData.contract && debtor.id_expedition_place
  );
}

function getCosignerIdExpeditionPlace(state) {
  const pf = state.portalFinancing;
  if (pf.cosigner_identification_type == 'Cédula de Extranjería' ) {
    return pf.dc_country;
  }

  return (
    getCosignerIdExpeditionPlaceFromPromissory(state) ||
    getCosignerIdExpeditionPlaceFromDataANI(state)
  );
}

function getCosignerIdExpeditionPlaceFromDataANI(state) {
  const pf = state.portalFinancing;
  return pf.id_cosigner_expedition_place || null;
}

function getCosignerIdExpeditionPlaceFromPromissory(state) {
  const pf = state.portalFinancing;
  const cosigner = _get(pf, 'promissory.cosigner');
  return (
    pf.promissory &&
    !pf.moveQuotaData.contract &&
    _get(cosigner, 'id_expedition_place', '')
  );
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalPromissoryPage);
