import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import _findIndex from 'lodash/findIndex';
import _compact from 'lodash/compact';
import _remove from 'lodash/remove';
import ActivityIndicator from '../../shared/ActivityIndicator';
import { history } from '../../../helpers/history';
import { getStreetTypeId } from '../../../helpers/utilityFunctions';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import UserProfile from './Profile';
import UserPointOfSales from './PointOfSales';
import UserBiometricReader from './BiometricReader';
import UserRoles from './Roles';
import UserAdditionalInfo from './AdditionalInfo';
import UserPreference from './UserPreferences';
import UserCoursesPandoty from './CoursesPandoty';

import EditProfileModal from './modals/EditProfileModal';
import ChangePasswordModal from './modals/ChangePasswordModal';
import EditProfilePictureModal from './modals/EditProfilePictureModal';
import EditRolesModal from './modals/EditRolesModal';
import EditPointOfSaleModal from './modals/EditPointOfSaleModal';
import EditBiometricReaderModal from './modals/EditBiometricReaderModal';

import ConfirmDialogModal from '../../shared/Modals/ConfirmDialog';
import DataAuthorizationModal from '../../shared/Modals/DataAuthorizationModal';
import EditAdditionalInfoModal from './modals/EditAdditionalInfoModal';
import ResetPasswordModal from './modals/ResetPasswordModal';

import { addressComponent } from '../../../constants/enums';

class UserDetail extends React.Component {
  state = {
    dialogReqPassword: false,
    isOpenedEditProfileModal: false,
    isOpenedChangePasswordModal: false,
    isOpenedEditPointOfSalesModal: false,
    isOpenedEditRoles: false,
    isSurfaceCoordinator: this.props.currentUser
      .canCreateSellerAsHugeSurfaceCoordinator,
    canListSalesmanRoles: this.props.currentUser.canListSalesmanRoles,
    isOpenedEditProfilePictureModal: false,
    isOpenConfirmDeactivateUserModal: false,
    isOpenedEditAdditionalInformation: false,
    isOpenConfirmDeleteProfilePicture: false,
    isLoading: true,
    isEditUserOpen: false,
    isOpenedEditBiometricReaderModal: false,
    isLoading: true,
    isBlockModalConfirmationOpen: false,
    userBlockedModalNotification: false,
    isResetPasswordModalOpen: false
  };

  /**
   * Splits the address into its diferrent components.
   * @returns The address' components.
   */
  _getAddressComponents = () => {
    //const user = this._getUser();
    const address = this._getUser().address || ',,,';

    const addressArray = address.split(',');
    const streetAddressArray = addressArray[
      addressComponent.streetAddress.pos
    ].split(' ');
    let name = '';
    let pos = -1;
    const addressComponents = {};

    for (const component in addressComponent) {
      name = addressComponent[component].name;
      pos = addressComponent[component].pos;

      addressComponents[name] = addressArray[pos];
    }

    for (const component in addressComponent.streetAddress.info) {
      name = addressComponent.streetAddress.info[component].name;
      pos = addressComponent.streetAddress.info[component].pos;

      if (streetAddressArray.length === 5) {
        if (component === addressComponent.streetAddress.info.initialStreet.name ||
          component === addressComponent.streetAddress.info.intersectingStreet.name
        ) {
          addressComponents[name] = getStreetTypeId(streetAddressArray[pos]);
        } else {
          addressComponents[name] = streetAddressArray[pos];
        }
      } else {
        addressComponents[name] = '';
      }
    }

    return addressComponents;
  };

  _handleCloseDialogReqPass = () => {
    this.setState({ dialogReqPassword: false });
  };

  async componentWillMount() {
    if (!this.props.location.pathname.includes('/my-profile')) {
      if (this.props.currentUser.id == this.props.match.params.id) {
        history.push('/my-profile');
      }
    }
    await this.fetchDetails();
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      ...this.props.currentUser
    });

    if (!this.props.suppliers) {
      this.props.fetchOrgData();
    }
    if (this.props.currentUser.canUpdateRoles) {
      this.props.fetchAllRoles();
      this.props.fetchRoles();
    }
    if (!this.props.currentUser.mobileConfirmedAt) {
      this._handleOpenEditProfileModal();
    }
  }

  fetchDetails = async () => {
    let id = this.props.match.params.id;
    this.props.location.pathname.includes('/my-profile')
      ? (id = this.props.currentUser.id)
      : await this.props.fetchUserInfo(id).then(isCorrect => {
        if (!isCorrect.success) {
          history.push('/');
        }
      });
    this.props.fetchPointsOfSaleNamesAssociation({ id: id });
    this.setState({ isLoading: false });
  };

  _handleOpenEditProfileModal = () =>
    this.setState({ isOpenedEditProfileModal: true });
  _handleCloseEditProfileModal = () =>
    this.setState({ isOpenedEditProfileModal: false });

  _handleOpenEditAdditionalInfoModal = () =>
    this.setState({ isOpenedEditAdditionalInformation: true });

  _handleCloseEditAdditionalInfoModal = () =>
    this.setState({ isOpenedEditAdditionalInformation: false });

  _handleOpenedEditProfilePictureModal = () => {
    this.setState({ isOpenedEditProfilePictureModal: true });
  };

  _handleCloseEditProfilePictureModal = () =>
    this.setState({ isOpenedEditProfilePictureModal: false });

  _handleSaveProfilePicture = picture => {
    const user = this._getUser();
    user.photo = picture;
    this.props.updateUserPictureProfile(user, { picture: picture });
  };

  _handleDeleteProfilePicture = () => {
    const user = this._getUser();
    user.photo = null;
    this.props.deletePictureProfile(user);
    this._handleCloseDeleteProfilePictureDialog();
  };

  _handleOpenDeleteProfilePictureDialog = () =>
    this.setState({ isOpenConfirmDeleteProfilePicture: true });

  _handleCloseDeleteProfilePictureDialog = () =>
    this.setState({ isOpenConfirmDeleteProfilePicture: false });

  _handleSaveProfileModal = personalData => {
    const user = this._getUser();
    return this.props.updatePersonalData(user, personalData);
  };

  _handleSaveAdditionalInfo = userAdditionalInfo => {
    const user = this._getUser();
    this.props.updateAdditionalInfo(user, userAdditionalInfo);
  };

  _handleSaveProfile = userDetails => {
    const user = this._getUser();
    if (!this.state.canUpdateGenericField) {
      delete userDetails.generic;
    }
    if (!this.state.canUpdateTheReceiptOfSchedulingNotifications) {
      delete userDetails.sendNotifications;
    }
    if (!this.state.canUpdateHasBiometricReaderField) {
      delete userDetails.hasBiometricReader;
    }
    this.props.updateUser(user, { user: { ...userDetails } }, 'UpdateUser');
  };

  _handleOpenChangePasswordModal = () =>
    this.setState({ isOpenedChangePasswordModal: true });
  _handleCloseChangePasswordModal = () =>
    this.setState({ isOpenedChangePasswordModal: false });

  _handleChangePassword = passwordInfo => {
    const user = this._getUser();
    return this.props.updatePassword(user, { user: passwordInfo });
  };

  _handleResendConfirmationEmail = () => {
    const user = this._getUser();
    if (user.blocked && this.props.isBlackListActive) {
      this.setState({ userBlockedModalNotification: true });
    } else {
      this.props.resendConfirmationEmail(user);
    }
  };

  _handleOpenDeactivateUserModal = () =>
    this.setState({ isOpenConfirmDeactivateUserModal: true });
  _handleCloseDeactivateUserModal = () =>
    this.setState({ isOpenConfirmDeactivateUserModal: false });
  _handleDeactivateUser = () => {
    const user = this._getUser();
    const signInRole = this._getSignInRole();
    const currentUserRoles = [...user.roles];
    _remove(currentUserRoles, r => String(r.id) === String(signInRole.id));
    const newRoles = currentUserRoles.map(r => r.id);
    this.props.updateUser(
      user,
      { user: { roles_ids: newRoles } },
      'ActivateUserToggle'
    );
    this._handleCloseDeactivateUserModal();
  };
  _handleActivateUser = () => {
    const user = this._getUser();
    if (user.blocked && this.props.isBlackListActive) {
      this.setState({ userBlockedModalNotification: true });
    } else {
      const signInRole = this._getSignInRole();
      const currentUserRoles = [...user.roles];
      const newRoles = [...currentUserRoles, signInRole].map(r => r.id);
      this.props.updateUser(
        user,
        { user: { roles_ids: newRoles } },
        'ActivateUserToggle'
      );
    }
  };

  _handleOpenEditRolesModal = () => {
    const user = this._getUser();
    if (user.blocked && this.props.isBlackListActive) {
      this.setState({ userBlockedModalNotification: true });
    } else {
      this.setState({ isOpenedEditRoles: true });
    }
  };

  _handleCloseEditRolesModal = () =>
    this.setState({ isOpenedEditRoles: false });

  _handleSaveRoles = (newRoles, type = 'UpdateRoles') => {
    const user = this._getUser();
    this.props.updateUser(user, { user: { roles_ids: newRoles } }, type);
    this.props.fetchPointsOfSaleNamesAssociation({ id: user.id });
  };

  _handleOpenPointOfSalesModal = () => {
    const user = this._getUser();
    if (user.blocked && this.props.isBlackListActive) {
      this.setState({ userBlockedModalNotification: true });
    } else {
      this.setState({
        isOpenedEditPointOfSalesModal: true
      });
    }
  };
  _handleClosePointOfSalesModal = () =>
    this.setState({ isOpenedEditPointOfSalesModal: false });
  _handleSavePointOfSales = async newPOS => {
    const user = this._getUser();
    await this.props.updatePointOfSaleOfUsers(user, {
      user: { point_of_sales: [...newPOS] }
    });
    if (
      this.props.currentUser.roles.some(role =>
        this.props.coordinatorRoles
          .concat(this.props.hugeSurfaceCoordinatorRoles)
          .includes(role.name)
      )
    ) {
      await this.props.fetchPointsOfSaleNamesAssociation({ id: user.id });
    }
  };

  _handleOpenBiometricReaderModal = () => {
    const user = this._getUser();
    if (user.blocked && this.props.isBlackListActive) {
      this.setState({ userBlockedModalNotification: true });
    } else {
      this.setState({ isOpenedEditBiometricReaderModal: true });
    }
  };
  _handleCloseBiometricReaderModal = () =>
    this.setState({ isOpenedEditBiometricReaderModal: false });
  _handleSaveBiometricReader = (
    biometricReaderInfo,
    type = 'UpdateBiometricReader'
  ) => {
    const user = this._getUser();
    this.props.updateUser(
      user,
      { user: { biometricReader: biometricReaderInfo } },
      type
    );
  };

  _getSignInRole = () => {
    const { roles } = this.props;
    const signInRole = roles.find(role => role.name === 'sign_in');
    return signInRole;
  };

  _getUser = () => {
    const user = this.props.match.params.id
      ? {
        ...this.props.userInfo,
        isCurrentUser: false
      }
      : {
        ...this.props.currentUser,
        active: true,
        isCurrentUser: true
      };
    return user;
  };

  _getUserMinifiedPointOfSale = user => {
    if (user.pointOfSales) {
      const userPointOfSaleMinified = user.pointOfSales.map(pos => {
        return {
          id: pos.id,
          name: pos.name
        };
      });
      return userPointOfSaleMinified;
    } else {
      return {};
    }
  };

  _canUpdateThisPictureProfile = () => {
    return this.props.match.params.id
      ? this.state.canUpdateAnotherUserPictureProfile
      : this.state.canUpdatePictureProfile;
  };
  _canUpdateThisAdditionalInfo = () => {
    return this.props.match.params.id
      ? this.state.canUpdateAnotherUserAdditionalInfo
      : this.state.canUpdateAdditionalInfo;
  };

  _isCoordinator = () => {
    return this.props.currentUser.roles &&
      this.props.coordinatorRoles &&
      this.props.hugeSurfaceCoordinatorRoles
      ? this.props.currentUser.roles.some(role =>
        this.props.coordinatorRoles
          .concat(this.props.hugeSurfaceCoordinatorRoles)
          .includes(role.name)
      )
      : true;
  };

  _isAdmin() {
    const currentUserRoles = [];
    this.props.currentUser.roles.map(role => {
      currentUserRoles.push(role.name);
    });

    if (this.props.adminRoles.some(r => currentUserRoles.includes(r))) {
      return true;
    } else {
      return false;
    }
  }

  _sendResetPassword = email => {
    return this.props.sendResetPassword({ email: email }, true);
  };
  _updatePersonalDataHandlingPermission = val => {
    return this.props
      .updateUser(
        this._getUser(),
        {
          personal_data_handling: val
        },
        'updatePersonalDataHandlingPermission'
      )
      .then(({ success }) => {
        if (success) {
          this._handleCloseEditUserModal();
        }
      });
  };

  _handleOpenEditUserModal = () => {
    const user = this._getUser();
    if (user.blocked && this.props.isBlackListActive) {
      this.setState({ userBlockedModalNotification: true });
    } else {
      this.setState({ isEditUserOpen: true });
    }
  };
  _handleCloseEditUserModal = () => this.setState({ isEditUserOpen: false });
  _handleBlockModalConfirmation = () =>
    this.setState({
      isBlockModalConfirmationOpen: !this.state.isBlockModalConfirmationOpen
    });

  _handleConfirmBlockModalConfirmation = () => {
    this._updateUserBlocked(!this._getUser().blocked);
    this.setState({ isBlockModalConfirmationOpen: false });
  };

  _handleCloseModalModalNotification = () =>
    this.setState({ userBlockedModalNotification: false });

  handleToggleResetPasswordModal = () => {
    this.setState({ isResetPasswordModalOpen: !this.state.isResetPasswordModalOpen });
  };

  _updateUserBlocked = val => {
    return this.props.updateUser(
      this._getUser(),
      {
        blocked: val,
        supplier_id: null
      },
      'updateUserBlocked'
    );
  };

  render() {
    const {
      classes,
      newAlert,
      updateMedia,
      pointsOfSales = [],
      osfMaxIdLength,
      requestRolesSend,
      confirmUpdateMedia,
      requestUserDetailSend,
      requestUpdateRolesSend,
      requestPointOfSalesSend,
      requestChangePasswordSend,
      requestErrorsOnUpdateMedia,
      requestUpdatePersonalDataSend,
      requestActivateUserToggleSend,
      requestDeletePictureProfileSend,
      requestUpdateAdditionalInfoSend,
      requestErrorsOnUpdatePersonalData,
      requestResendConfirmationEmailSend,
      requestUpdateUserPictureProfileSend,
      requestUpdatePointOfSalesOfUserSend,
      sendChangePasswordByAdmin,
      coordinatorRoles,
      hugeSurfaceCoordinatorRoles,
      companyName,
      isBlackListActive,
      maritalStatus
    } = this.props;

    const {
      canUpdateRoles,
      canUpdateUsers,
      canUpdateUserPoS,
      canAddCoordinator,
      canRelatePOSToSeller,
      canModifyUserSupplier,
      canUpdateGenericField,
      canUpdateHasBiometricReaderField,
      canListPandotyCoursesInMyProfile,
      canUpdateUserPassword,
      canResendConfirmationEmail,
      canUpdateTheReceiptOfSchedulingNotifications,
      canUpdateUserPersonalData,
      canUpdateAnotherUserPersonalData,
      canUnblockUsers,
      canEditBlackList
    } = this.props.currentUser;

    const user = this._getUser();
    if (!user || this.state.isLoading) {
      return <ActivityIndicator />;
    }
    const userPointOfSaleMinified = this._getUserMinifiedPointOfSale(user);

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1
            className={classnames(
              classes.headline4,
              classes.noMarginBottom,
              classes.title
            )}
          >
            {user.isCurrentUser
              ? 'Mi Perfil'
              : `Usuario ${user.id == null ? '' : `#${user.id}`}`}
          </h1>
        </div>
        <Grid container spacing={24}>
          {!this.props.currentUser.mobileConfirmedAt &&
            !this.state.isOpenedEditProfileModal && (
              <Grid item xs={12}>
                <Card className={classes.mobileNotConfirmed}>
                  <p>
                    Debe confirmar su número celular para acceder a todas las
                    funciones. Puede hacer esto dando clic en Editar Datos
                    Personales.
                  </p>
                </Card>
              </Grid>
            )}
          <Grid item xs={12} lg={6}>
            <UserProfile
              user={user}
              onEditProfile={this._handleOpenEditProfileModal}
              onEditProfilePicture={this._handleOpenedEditProfilePictureModal}
              onChangePassword={this._handleOpenChangePasswordModal}
              onResendConfirmationEmail={this._handleResendConfirmationEmail}
              onDeactivateUser={this._handleOpenDeactivateUserModal}
              onActivateUser={this._handleActivateUser}
              isUpdatingUserProfile={
                requestUserDetailSend || requestUpdatePersonalDataSend
              }
              isUpdatingPictureProfile={
                requestUpdateUserPictureProfileSend ||
                requestDeletePictureProfileSend
              }
              isChangingStatus={requestActivateUserToggleSend}
              isChangingPassword={requestChangePasswordSend}
              isChangingResendConfirmationEmail={
                requestResendConfirmationEmailSend
              }
              canUpdateUsers={canUpdateUsers}
              canUpdateRoles={canUpdateRoles}
              canUpdateUserPassword={canUpdateUserPassword}
              canResendConfirmationEmail={canResendConfirmationEmail}
              OpenDeleteProfilePictureDialog={
                this._handleOpenDeleteProfilePictureDialog
              }
              sendResetPassword={this._sendResetPassword}
              canUpdatePictureProfile={this._canUpdateThisPictureProfile()}
              canUnblockUsers={canUnblockUsers}
              canEditBlackList={canEditBlackList}
              isBlackListActive={isBlackListActive}
              handleBlockModalConfirmation={this._handleBlockModalConfirmation}
              handleToggleResetPasswordModal={this.handleToggleResetPasswordModal}
            />
            <UserAdditionalInfo
              isLoading={
                requestUserDetailSend || requestUpdateAdditionalInfoSend
              }
              additionalInfo={{
                activities: user.activities,
                gender: user.gender,
                dateOfBirth: user.dateOfBirth,
                numOfChildren: user.numOfChildren,
                maritalStatus: user.maritalStatus,
                address: this._getAddressComponents()
              }}
              onEditAdditionalInfo={this._handleOpenEditAdditionalInfoModal}
              canUpdateAdditionalInfo={this._canUpdateThisAdditionalInfo()}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            {canListPandotyCoursesInMyProfile && user.isCurrentUser && user.pandotyExternalCode && (
              <UserCoursesPandoty
                courses={user.pandotyCourses ? user.pandotyCourses.courses : null}
              />
            )}
            <UserRoles
              canUpdateRoles={canUpdateRoles}
              roles={user.roles}
              onEditRoles={this._handleOpenEditRolesModal}
              isLoading={
                requestUserDetailSend ||
                requestRolesSend ||
                requestUpdateRolesSend
              }
            />
            <UserPointOfSales
              canUpdatePOS={
                canAddCoordinator ||
                (canRelatePOSToSeller &&
                  !this._isCoordinator(user.roles, coordinatorRoles)) ||
                canUpdateUserPoS
              }
              onEditPointOfSales={this._handleOpenPointOfSalesModal}
              pointsOfSales={user.pointOfSales}
              userId={user.id}
              isLoading={
                requestUserDetailSend ||
                requestPointOfSalesSend ||
                requestUpdatePointOfSalesOfUserSend
              }
            />
            {((user.isCurrentUser && canUpdateUserPersonalData) ||
              (!user.isCurrentUser && canUpdateAnotherUserPersonalData)) && (
                <UserPreference
                  canUpdateUser={true}
                  onEditUser={this._handleOpenEditUserModal}
                  user={user}
                  isLoading={
                    requestUserDetailSend ||
                    requestPointOfSalesSend ||
                    requestUpdatePointOfSalesOfUserSend
                  }
                />
              )}
            {user.hasBiometricReader && (
              <UserBiometricReader
                canUpdateHasBiometricReader={canUpdateHasBiometricReaderField}
                onEditBiometricReader={this._handleOpenBiometricReaderModal}
                biometricReader={user.biometricReader}
                pointsOfSales={userPointOfSaleMinified}
                isLoading={
                  requestPointOfSalesSend || requestUpdatePointOfSalesOfUserSend
                }
              />
            )}
            <ResetPasswordModal
              open={this.state.isResetPasswordModalOpen}
              user={user}
              closeModal={this.handleToggleResetPasswordModal}
              sendChangePasswordByAdmin={sendChangePasswordByAdmin}
              sendResetPassword={this._sendResetPassword}
            />
          </Grid>
        </Grid>
        {this.state.isEditUserOpen && (
          <DataAuthorizationModal
            title={'Autorización y tratamiento de datos personales'}
            companyName={companyName}
            buttonTextCancel={'Cancelar'}
            isDataAuthorizationAccepted={user.personalDataHandling}
            disableBackdropClick={true}
            onClose={this._handleCloseEditUserModal}
            onConfirm={this._updatePersonalDataHandlingPermission}
          />
        )}
        {this.state.isOpenedEditProfileModal &&
          !this.props.currentUser.passwordUpdateInfo
            .passwordUpdateIsRequired && (
            <EditProfileModal
              currentUser={this.props.currentUser}
              user={user}
              onClose={this._handleCloseEditProfileModal}
              onSave={this._handleSaveProfileModal}
              confirmUpdateMedia={confirmUpdateMedia}
              updateMedia={updateMedia}
              canUpdateUsers={canUpdateUsers}
              openOtp={this.props.currentUser.mobileConfirmedAt ? false : true}
              requestNewOtpSend={this.props.requestNewOtpSend}
              requestErrorsOnUpdateMedia={requestErrorsOnUpdateMedia}
              requestErrorsOnUpdatePersonalData={
                requestErrorsOnUpdatePersonalData
              }
              canUpdateGenericField={canUpdateGenericField}
              canUpdateTheReceiptOfSchedulingNotifications={
                canUpdateTheReceiptOfSchedulingNotifications
              }
              canUpdateHasBiometricReaderField={
                canUpdateHasBiometricReaderField
              }
              suppliers={this.props.suppliers}
              canModifyUserSupplier={canModifyUserSupplier}
              osfMaxIdLength={osfMaxIdLength}
              hugeSurfaceCoordinatorRoles={hugeSurfaceCoordinatorRoles}
              isBlackListActive={isBlackListActive}
            />
          )}
        {this.state.isOpenedEditProfilePictureModal && (
          <EditProfilePictureModal
            user={user}
            newAlert={newAlert}
            canUpdatePictureProfile={this._canUpdateThisPictureProfile()}
            canUpdateTheReceiptOfSchedulingNotifications={
              canUpdateTheReceiptOfSchedulingNotifications
            }
            suppliers={this.props.suppliers}
            onClose={this._handleCloseEditProfilePictureModal}
            onSave={this._handleSaveProfilePicture}
            errors={this.props.requestErrorsOnUpdateUser}
            isSubmitting={this.props.requestUpdateUserSend}
          />
        )}
        {this.state.isOpenedChangePasswordModal && (
          <ChangePasswordModal
            isSubmitting={requestChangePasswordSend}
            isCurrentUser={user.isCurrentUser}
            onClose={this._handleCloseChangePasswordModal}
            onSave={this._handleChangePassword}
          />
        )}
        {this.state.isOpenConfirmDeactivateUserModal && (
          <ConfirmDialogModal
            title={'¿Quieres desactivar a este usuario?'}
            text={`Con esta acción ${_compact([
              user.firstName,
              user.lastName
            ]).join(
              ' '
            )} no podrá ingresar al Sistema Brilla hasta que sea activado de nuevo.`}
            buttonTextConfirm={'Desactivar'}
            buttonTextCancel={'Cancelar'}
            disableBackdropClick={true}
            onClose={this._handleCloseDeactivateUserModal}
            onConfirm={this._handleDeactivateUser}
          />
        )}
        {this.state.isOpenConfirmDeleteProfilePicture && (
          <ConfirmDialogModal
            title={'Se eliminará la imagen de perfil'}
            buttonTextConfirm={'Confirmar'}
            buttonTextCancel={'Cancelar'}
            disableBackdropClick={true}
            onClose={this._handleCloseDeleteProfilePictureDialog}
            onConfirm={this._handleDeleteProfilePicture}
          />
        )}
        {this.state.isOpenedEditRoles && (
          <EditRolesModal
            currentRoles={user.roles.map(role => ({
              value: String(role.id),
              label: role.displayName
            }))}
            canListSalesmanRoles={this.props.canListSalesmanRoles}
            currentUser={this.props.currentUser}
            roles={this.props.roles.map(role => ({
              value: String(role.id),
              label: role.displayName
            }))}
            signInRole={this._getSignInRole()}
            adminRoles={this.props.adminRoles}
            onClose={this._handleCloseEditRolesModal}
            onSave={this._handleSaveRoles}
          />
        )}
        {this.state.isOpenedEditAdditionalInformation && (
          <EditAdditionalInfoModal
            additionalInfo={{
              activities: user.activities,
              gender: user.gender,
              dateOfBirth: user.dateOfBirth,
              numOfChildren: user.numOfChildren,
              maritalStatus: user.maritalStatus,
              address: this._getAddressComponents()
            }}
            maritalStatus={maritalStatus}
            newAlert={newAlert}
            onClose={this._handleCloseEditAdditionalInfoModal}
            onSave={this._handleSaveAdditionalInfo}
          />
        )}
        {this.state.isOpenedEditPointOfSalesModal && (
          <EditPointOfSaleModal
            onClose={this._handleClosePointOfSalesModal}
            pointsOfSales={pointsOfSales}
            selectedPointsOfSales={user.pointOfSales}
            onSave={this._handleSavePointOfSales}
            canShowSelectUsers={canAddCoordinator || canRelatePOSToSeller}
            currentUser={this.props.currentUser}
            coordinatorRoles={coordinatorRoles.concat(
              hugeSurfaceCoordinatorRoles
            )}
          />
        )}
        <ConfirmDialogModal
          open={this.state.dialogReqPassword}
          title="Mensaje Importante"
          text={this.state.specs}
          buttonTextConfirm="ENTENDIDO"
          onConfirm={this._handleCloseDialogReqPass}
          onClose={this._handleCloseDialogReqPass}
        />
        {this.state.isOpenedEditBiometricReaderModal && (
          <EditBiometricReaderModal
            onClose={this._handleCloseBiometricReaderModal}
            onSave={this._handleSaveBiometricReader}
            biometricReader={user.biometricReader}
            pointsOfSale={userPointOfSaleMinified}
          />
        )}
        {this.state.isBlockModalConfirmationOpen && (
          <ConfirmDialogModal
            title={'Confirmación de acción'}
            text={`¿Está seguro de que desea ${!user.blocked ? 'añadir a este asesor a' : 'remover a este asesor de'}  la Lista Negra?`}
            buttonTextConfirm={'Aceptar'}
            buttonTextCancel={'Cancelar'}
            disableBackdropClick={true}
            onClose={this._handleBlockModalConfirmation}
            onConfirm={this._handleConfirmBlockModalConfirmation}
          />
        )}
        {this.state.userBlockedModalNotification && (
          <ConfirmDialogModal
            title={'Notificación de bloqueo'}
            text={
              user.isCurrentUser
                ? `Lo sentimos, tiene deshabilitada esta función. Por favor, comuniquese con el area comercial.`
                : `Lo sentimos, el asesor seleccionado tiene deshabilitada esta función.`
            }
            buttonTextConfirm={'Aceptar'}
            disableBackdropClick={true}
            onClose={this._handleCloseModalModalNotification}
            onConfirm={this._handleCloseModalModalNotification}
          />
        )}
      </div>
    );
  }
}

const styles = () => ({
  mobileNotConfirmed: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1em',
    color: '#DB4945',
    fontSize: 'large',
    fontWeight: 'bold'
  }
});

UserDetail.propTypes = {
  location: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  currentUser: PropTypes.object.isRequired,
  permissions: PropTypes.array,
  userInfo: PropTypes.object,
  fetchUserInfo: PropTypes.func,
  newAlert: PropTypes.func,
  updateUser: PropTypes.func,
  updatePointOfSaleOfUsers: PropTypes.func,
  updateAdditionalInfo: PropTypes.func,
  updateUserPictureProfile: PropTypes.func
};

export default withStyles(
  combineStyles(
    styles,
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles
  )
)(UserDetail);
