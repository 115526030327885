import _get from 'lodash/get';
import { SET_CURRENT_USER, RESET_APP, SET_COURSE_DETAIL } from '../constants/action_constants';
import { newAlert, serverStatusError } from './alert_actions';
import { history } from '../helpers/history';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest } from './request_state';
import { fetchOrgData } from './global_setting_actions';
const requestService = new RequestService('node');
const requestServiceRails = new RequestService();

export function setCurrentUser(data) {
  return {
    type: SET_CURRENT_USER,
    data: data,
    permissions: data.permissions.map((p) => p.action),
  };
}

export function setCourseDetail(data) {
  return {
    type: SET_COURSE_DETAIL,
    data: data,
  };
}

export const login = (email, password) => async (dispatch) => {
  dispatch(sendRequest('Login'));
  try {
    const payload = await requestService.post('sessions',
      {
        login: email,
        password: password,
      },
    );
    const currentUser = payload.data.attributes;
    dispatch(setCurrentUser(currentUser));
    dispatch(newAlert('success', 's', 'Bienvenido al Sistema Brilla'));
    dispatch(fetchOrgData());
    history.push('/');
    return true;
  } catch (errors) {
    dispatch(serverStatusError(errors));
    if (errors) {
      const e = await errors.json();
      e &&
        dispatch(
          newAlert('error', 'ERROR:', e.errors.base)
        );
    }
    return false;
  } finally {
    dispatch(completeRequest('Login'));
  }
};

export function logout() {
  history.push('/login');
  return {
    type: RESET_APP,
    data: null,
  };
};

export const fetchCoursesDetails = (course_id) => async dispatch => {
  dispatch(sendRequest());
  try {
    const payload = await requestServiceRails.post(
      'users/pandoty_course_detail',
      {
        course_id: course_id
      }
    );
    dispatch(setCourseDetail(payload.data));
  } catch (errors) {
    errors.json().then(e => {
      dispatch(newAlert('error', 'ERROR:', e.errors.base));
    });
  } finally {
    dispatch(completeRequest());
  }
};
