import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { withRouter } from 'react-router-dom';
import PortalValidateInvoicePage from './PortalValidateInvoicePage';
import { nextStep, setCurrentStep } from '../../../actions/stepper_actions';
import {
  checkFifthStep,
  checkInvoiceResponse,
  fetchGlobalParameters,
  fetchQuestionsInvoice,
  resetInvoiceValidationSkipped,
  skipInvoiceValidationStep
} from '../../../actions/portal_financing_actions';
import { fetchModalsImage } from '../../../actions/announcements_actions';
import { newAlert } from '../../../actions/alert_actions';

function mapStateToProps(state) {
  const pf = state.portalFinancing;
  return {
    canViewInvoiceQuestions:
      state.authentication.currentUser.canViewInvoiceQuestions,
    canCheckInvoiceAnswers:
      state.authentication.currentUser.canCheckInvoiceAnswers,
    process: state.stepper.process,
    nextStep: state.stepper.nextStep,
    portalNext: state.stepper.portalNext,
    promissoryNext: state.stepper.promissoryNext,
    portalFinancing: pf,
    portalIsLoading: pf.portalIsLoading,
    contract: pf.debtor_contract_number,
    debtorIdentification: pf.debtor_identification,
    serverErrors: pf.errors,
    invoice1: pf.invoice1,
    invoice2: pf.invoice2,
    invoices_valid: pf.invoices_valid,
    announcements: state.announcement.modalContent,
    questionsInvoice: pf.questionsInvoice,
    routes: state.stepper.routes,
    gnpGasApplianceSaleEvaluation: pf.gnp_gas_appliance_evaluation,
    validateWithOneInvoice: pf.validate_with_one_invoice,
    invoiceValidationData:
      state.globalSetting.orgData.sale_invoice_validation_step,
    supplierCanSkipStep: pf.supplier.can_skip_invoice_validation,
    isSecureSale: pf.isSecureSale
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setCurrentStep: bindActionCreators(setCurrentStep, dispatch),
    skipStep: bindActionCreators(skipInvoiceValidationStep, dispatch),
    checkFifthStep: bindActionCreators(checkFifthStep, dispatch),
    fetchQuestionsInvoice: bindActionCreators(fetchQuestionsInvoice, dispatch),
    checkInvoiceResponse: bindActionCreators(checkInvoiceResponse, dispatch),
    fetchGlobalParameters: bindActionCreators(fetchGlobalParameters, dispatch),
    fetchModalsImage: bindActionCreators(fetchModalsImage, dispatch),
    newAlert: bindActionCreators(newAlert, dispatch),
    resetInvoiceValidationSkipped: bindActionCreators(
      resetInvoiceValidationSkipped,
      dispatch
    )
  };
}

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(PortalValidateInvoicePage);
