import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import BrandEditCreate from '../BrandEditCreate';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';

import parseQuerystring from '../../../helpers/parseQuerystring';
import BrandFilters from './BrandFilters';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class BrandsList extends React.PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE
  };

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchBrands({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleNewBrand = () => {
    this.props.toggleModal(true, false, {});
  };
  _handleOpenBrand = (brand, canUpdateBrands) => {
    if (canUpdateBrands) {
      this.props.toggleModal(true, true, {
        ...brand,
        description: brand.description === '--' ? '' : brand.description || ''
      });
    }
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };

  render() {
    const { 
      brands = [],
      meta = {},
      classes, 
      requestSend,
      canCreateBrands,
      canUpdateBrands
     } = this.props;
    const { page } = parseQuerystring(this.props) || {}; 
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Marcas'}
          </h1>
          {canCreateBrands && (<Button
            className={classes.button}
            classes={{
              label: classes.buttonLabel
            }}
            variant="contained"
            color="secondary"
            onClick={this._handleNewBrand}
          >
            {'Crear Marca'}
          </Button>)}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <BrandFilters onChangeFilter={this.fetchItems} />
          </div>
        </div>
        <div>
          <Table
            name={'Marca'}
            columns={columns}
            data={mapData(brands)}
            onDownloadClick={this._handleDownloadCSV}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleOpenBrand(props, canUpdateBrands)}
            totalCount={meta && meta.totalCount || 0}
            loading={requestSend}
          />
        </div>
        <BrandEditCreate reFetch={this.fetchItems} />
      </div>
    );
  }
}

BrandsList.propTypes = {
  classes: PropTypes.object.isRequired,
  brands: PropTypes.array.isRequired,
  fetchBrands: PropTypes.func.isRequired,
  meta: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  canUpdateBrands: PropTypes.bool,
  canCreateBrands: PropTypes.bool
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(BrandsList);
