import { FILE_URL_SET, FILE_ERROR_SET } from '../constants/action_constants';
import MIMETYPES from '../constants/mimetypes';

const initialState = {
  voucherUrl: null,
  promissoryUrl: null,
  amortizationUrl: null,
  secureUrl: null,
  binUrl: null,
  informationLetterUrl: null,
  cardifUrl: null,
  cardifLayerTwo: null,
  voucherV2Url: null,
  voucherV2TsUrl: null
};

export default (state = initialState, action = {}) => {
  const fileName = `${action.fileType}Url`;
  switch (action.type) {
    case FILE_URL_SET:
      const file = new Blob(
        [
          new Uint8Array(
            action.data.bufferArray.data,
            action.data.byteOffset,
            action.data.bufferArray.data.length
          )
        ],
        { type: MIMETYPES[action.documentType] }
      );
      const fileUrl = URL.createObjectURL(file);
      return {
        ...state,
        [fileName]: fileUrl
      };
    case FILE_ERROR_SET:
      return {
        ...state,
        [fileName]: null
      };
    default:
      return state;
  }
};
