import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import QuotaTransferFilters from './QuotaTransferFilters';
import classnames from 'classnames';
import Table from '../../shared/Table/Table';
import { columns, currencyColumns, mapData } from './TableMetadata';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import QuotaTransferRevision from '../QuotaTransferRevision';

class QuotaTransferList extends Component {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    item: {
      id: '',
      quota_transfers: []
    },
    openModal: false
  };

  componentDidMount() {
    this._fetchItems();
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchTransfers({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleRowClick = item => {
    this._handleOpenModal(item);
  };

  _handleOpenModal = item => {
    this.setState({ item: item, openModal: true });
  };

  _handleCloseModal = () => {
    this.setState({ item: {}, openModal: false });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  render() {
    const {
      classes,
      transfers = [],
      meta = {},
      requestQuotaTransferSend = false
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Unión y Traslado'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <QuotaTransferFilters onChangeFilter={this._fetchItems} />
          </div>
        </div>
        <div>
          <Table
            name={'Solicitud'}
            columns={columns}
            currencyColumns={currencyColumns}
            data={mapData(transfers)}
            onDownloadClick={this._handleDownloadCSV}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleRowClick(props)}
            totalCount={(meta && meta.total_count) || 0}
            loading={requestQuotaTransferSend}
          />
        </div>
        {this.state.openModal && (
          <QuotaTransferRevision
            saleId={this.state.item.id}
            quotaTransfers={this.state.item.quota_transfers}
            item={this.state.item}
            reFetch={this._fetchItems}
            onClose={this._handleCloseModal}
          />
        )}
      </div>
    );
  }
}

const styles = () => ({
  link: {
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  ),
  { withTheme: true }
)(QuotaTransferList);
