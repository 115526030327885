import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import PointUserAssociationFilters from './PointUserAssociationFilters';
import PointUserAssociationRevision from './Modal/PointUserAssociationRevision';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';
import { history } from '../../../helpers/history';
import _isEmpty from 'lodash/isEmpty';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import ListsStyle from '../__styles__/Lists.style';

import RequestService from '../../../services/request_service';
const requestService = new RequestService();

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class PointUserAssociationList extends React.PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    revisionModal: false,
    pointUserAssociationInModal: {}
  };
  componentDidMount() {
    this.fetchItems();
    !this.props.causals && this.props.fetchOrgData();
    const pointOfSalesParams = {};
    if (this.props.currentUser.supplier) {
      pointOfSalesParams.supplier_id_eq = this.props.currentUser.supplier.id;
    }
    this.props.fetchPointsOfSaleNames(pointOfSalesParams);
    this.checkLocationState();
  }

  componentDidUpdate(prevProps) {
    this.checkLocationState();
    if (
      prevProps.pointUserAssociationUpdated !==
        this.props.pointUserAssociationUpdated &&
      this.props.pointUserAssociationUpdated
    ) {
      this._handleCloseRevisionModal();
    }
  }

  checkLocationState = async () => {
    const {
      location: { state }
    } = this.props;
    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const homeVisit = await this._fetchItem(state.entityId);
        const mappedHomeVisit = mapData(homeVisit);
        this._handleOpenRevisionModal(mappedHomeVisit[0]);
      }
    }
  };

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchPointUserAssociation({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _fetchItem = async id => {
    try {
      const payload = await requestService.get(
        'users/fetch/point_of_sales',
        { id_eq: id },
        true
      );
      return payload.data;
    } catch (errors) {
      return null;
    }
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleOpenRevisionModal = homeVisit => {
    this.setState({
      pointUserAssociationInModal: homeVisit,
      revisionModal: true
    });
  };

  _handleCloseRevisionModal = () => {
    this.setState({ pointUserAssociationInModal: {}, revisionModal: false });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };

  _handleUpdatePointUserAssociation = pointUserAssociation => {
    const query = parseQuerystring(this.props) || {};
    this.props.pointUserAssociationRevision(pointUserAssociation, {
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };
  _handleRevokeAssociation = pointUserAssociation => {
    const query = parseQuerystring(this.props) || {};
    this.props.pointUserAssociationRevoke(pointUserAssociation, {
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleFilterChange = filters => {
    this.fetchItems(filters);
  };

  dateFilterHasValidRange = filters => {
    const dateRange = this.getDateFilterRange(filters);
    if (_isEmpty(dateRange)) return false;
    const dateDiff = new Date(dateRange.end) - new Date(dateRange.start);
    const dateDiffInMonths = dateDiff / 1000 / 3600 / 24 / 31;
    return dateDiffInMonths < 1;
  };

  getDateFilterRange = filters => {
    const dateFilterValue = filters.date_range;
    if (!dateFilterValue) return {};
    const splittedDateFilterValue = dateFilterValue.split(' - ');
    const dateRange = {};
    dateRange.start = splittedDateFilterValue[0];
    dateRange.end = splittedDateFilterValue[1] || splittedDateFilterValue[0];
    return dateRange;
  };

  render() {
    const {
      pointUserAssociation = [],
      meta,
      causals = [],
      classes,
      requestSend = true,
      causalsLoading,
      pointsOfSale = []
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Asociación de asesores'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <PointUserAssociationFilters
              onChangeFilter={this._handleFilterChange}
              pointsOfSale={pointsOfSale}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Solicitud'}
            columns={columns}
            data={mapData(pointUserAssociation)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props => this._handleOpenRevisionModal(props)}
            totalCount={meta.total_count || 0}
            loading={requestSend || causalsLoading}
          />
        </div>
        {this.state.revisionModal && (
          <PointUserAssociationRevision
            pointUserAssociation={this.state.pointUserAssociationInModal}
            canApproveSeller={this.props.canApproveSeller}
            canRevokeSeller={this.props.canRevokeSeller}
            onSave={this._handleUpdatePointUserAssociation}
            onRevoke={this._handleRevokeAssociation}
            onClose={this._handleCloseRevisionModal}
            causals={causals}
            requestApprove={this.props.updatePointUserAssociationApprove}
            requestRevoke={this.props.updatePointUserAssociationRevoke}
          />
        )}
      </div>
    );
  }
}

PointUserAssociationList.propTypes = {
  classes: PropTypes.object.isRequired,
  fetchPointUserAssociation: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(PointUserAssociationList);
