import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import classnames from 'classnames';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import FormModal from './modals/FormModal';

class SubSegmentList extends React.PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE
  }

  componentDidMount() {
    this._fetchData();
  }

  _fetchData(params = {}) {
    this._fetchConfigs(params);
  }

  _fetchConfigs = (params = {}) => {
    const { meta } = this.props;
    const page = params.page || (meta && meta.currentPage) || 1;
    this.props.fetchSubSegmentsList({
      page: page,
      perPage: this.state.rowsPerPage
    });
  }

  _handleChangePage = page => {
    this._fetchConfigs({
      page: page + 1
    });
  }

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchConfigs);
  }

  _getTableName = () => {
    const { configs = [] } = this.props;
    return configs.length != 1
      ? 'Configuraciones'
      : 'Configuración';
  }

  _openModal = (params = {}) => {
    this.props.openFormModal();
    this.setState({
      currentConfig: this._getCurrentConfig(params.config)
    });
  }

  _closeModal = () => {
    this.props.closeFormModal();
  }

  _isLoadingData = () => {
    const {
      loading = false
    } = this.props;
    return loading;
  }

  _getCurrentConfig = config => {
    return config
  }

  _handleRowClick = config => {
    if (this.props.canUpdateSubsegments) {
      this._openModal({ config });
    }
  }

  render() {
    const {
      configs = [],
      meta = {},
      classes,
      modalSubmitLoading = false,
      updateConfig,
      formModalIsOpen
    } = this.props;
    const { currentPage } = meta;
    const { rowsPerPage } = this.state;
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Sub Segmentos'}
          </h1>
        </div>
        <div>
          <Table
            name={this._getTableName()}
            columns={columns}
            data={mapData(configs)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={currentPage - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={(meta && meta.totalCount) || 0}
            loading={this._isLoadingData()}
            onClickOnRow={this._handleRowClick}
          />
        </div>
        <div>
          {
            formModalIsOpen && (
              <FormModal
                onClose={this._closeModal}
                config={this.state.currentConfig}
                updateConfig={updateConfig}
                submitLoading={modalSubmitLoading}
                rowsPerPage={rowsPerPage}
                currentPage={currentPage}
              />
            )
          }
        </div>
      </div>
    );
  }
}

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(SubSegmentList);