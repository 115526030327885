import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import RangeEditCreate from '../PromissoryRangeEditCreate';
import Table from '../../shared/Table/Table';
import PromissoryFilters from './PromissoryFilters';
import { columns, mapData } from './TableMetadata';

import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class PromissoryRanges extends React.PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    openModal: false,
    rangeInModal: {},
    isEditing: false
  };

  componentDidMount() {
    this.fetchItems();
    if (this.props.corporations.length === 0) {
      this.props.fetchCorporationNames();
    }
  }

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchPromissoryRanges({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleOpenCreateModal = () => {
    this.setState({ rangeInModal: {}, openModal: true, isEditing: false });
  };

  _handleCloseModal = () => {
    this.setState({ rangeInModal: {}, openModal: false, isEditing: false });
  };

  _handleOpenEditModal = (rangeDetail, canUpdatePromissoryRanges) => {
    if (canUpdatePromissoryRanges) {
      this.setState({
        rangeInModal: rangeDetail,
        openModal: true,
        isEditing: true
      });
    }
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };

  render() {
    const {
      ranges = [],
      rangesMeta,
      classes,
      requestSend,
      canCreatePromissoryRanges,
      canUpdatePromissoryRanges
    } = this.props;

    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Rango Pagarés Manuales'}
          </h1>
          {canCreatePromissoryRanges && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateModal}
            >
              {'Crear Rango'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <PromissoryFilters
              onChangeFilter={this.fetchItems}
              corporations={this.props.corporations}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Rango'}
            columns={columns}
            data={mapData(ranges)}
            onDownloadClick={this._handleDownloadCSV}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props =>
              this._handleOpenEditModal(props, canUpdatePromissoryRanges)
            }
            totalCount={rangesMeta.total_count || 0}
            loading={requestSend}
          />
        </div>
        {this.state.openModal && (
          <RangeEditCreate
            reFetch={this.fetchItems}
            rangeDetail={this.state.rangeInModal}
            onClose={this._handleCloseModal}
            isEditing={this.state.isEditing}
          />
        )}
      </div>
    );
  }
}

PromissoryRanges.propTypes = {
  classes: PropTypes.object.isRequired,
  ranges: PropTypes.array.isRequired,
  fetchPromissoryRanges: PropTypes.func.isRequired,
  rangesMeta: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(PromissoryRanges);
