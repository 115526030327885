import React from 'react';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import BrandEditCreate from '../BrandEditCreate';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';

import parseQuerystring from '../../../helpers/parseQuerystring';
import FinancialPlanFilters from './FinancialPlanFilters';
import classnames from 'classnames';

import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';

import ListsStyle from '../__styles__/Lists.style';

import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class FinancialPlanList extends React.PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE
  };

  componentDidMount() {
    this.fetchItems();
  }

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchFinancialPlans({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };

  render() {
    const {
      financialPlans = [],
      meta = {},
      classes,
      loading = true
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Planes de Financiación'}
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <FinancialPlanFilters onChangeFilter={this.fetchItems} />
          </div>
        </div>
        <div>
          <Table
            name={'Financiación'}
            columns={columns}
            data={mapData(financialPlans)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            totalCount={(meta && meta.totalCount) || 0}
            loading={loading}
          />
        </div>
      </div>
    );
  }
}

FinancialPlanList.propTypes = {
  classes: PropTypes.object.isRequired,
  financialPlans: PropTypes.array.isRequired,
  fetchFinancialPlans: PropTypes.func.isRequired,
  meta: PropTypes.object,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

const styles = () => ({});

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(FinancialPlanList);
