import React, { PureComponent } from "react";
import { Grid, withStyles } from '@material-ui/core';
import CustomDialog from "../../shared/Modals/CustomDialog";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field, Formik } from 'formik';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import * as Yup from 'yup';
import OtpInput from 'react-otp-input';
import Button from '@material-ui/core/Button';
import Timer from "../../shared/Timer";

const validationSchema = Yup.object().shape({
  otp: Yup.string().test(
    'len',
    'Debe tener exactamente 4 caracteres',
    val => val && val.toString().length === 4
  )
});

class OTPV2Modal extends PureComponent {
  RESEND_FLAG_SECONDS_DELAY = 120;
  timer = null;


  state = {
    counter: this.RESEND_FLAG_SECONDS_DELAY,
    canResend: false,
    firstCounter: true,
  };

  resetResendFlag = () => {
    const countdown = c => c - 1;
    const interval = setInterval(() => {
      this.setState({ counter: countdown(this.state.counter) });
    }, 1000);
    this.timer = interval;
  };

  _handleChange = (otp, handleChange) => {
    const evt = { target: { value: otp, name: 'otp' } };
    handleChange(evt);
  };

  //validate this later: isDebtorMobile ? debtor.mobile : cosigner.mobile
  _handleValidateOtp = (otp, mobile, signer) => {
    const { transaccionId, cosigner, otpValidatorDebtor, otpValidatorCosigner, isRemoteTeleshopping } = this.props;
    const validateOtpFunction = isRemoteTeleshopping ? this.props.validateOtpV2Teleshopping : this.props.validateOtpV2;

    validateOtpFunction({
      idTransaccion: transaccionId,
      numeroCelular: mobile,
      otpNumero: otp,
      signer
    }).then((response) => {
      if (response && response.valid) {
        //validate if cosigner exists and open the modal again for the second otp
        this.props.handleCloseOtpModal();
        if (cosigner && !otpValidatorDebtor && !otpValidatorCosigner) {
          setTimeout(() => {
            clearInterval(this.timer);
            this.setState({ counter: this.RESEND_FLAG_SECONDS_DELAY, canResend: false });
            this.resetResendFlag();
            this.props.handleOpenOtpModal()
          }, 1000);
        }
      }
    })
  };

  _handleForward = (mobile) => {
    const { transaccionId, isRemoteTeleshopping } = this.props;
    if (!this.state.canResend) return;
    const forwardOtpFunction = isRemoteTeleshopping ? this.props.reSendOtpV2Teleshopping : this.props.reSendOtpV2;
    forwardOtpFunction({
      transaccionId: transaccionId,
      numeroCelular: mobile
    }).then((valid) => {
      if (valid) {
        this.setState({ counter: this.RESEND_FLAG_SECONDS_DELAY, canResend: false });
        this.resetResendFlag();
      }
    })
  };

  componentDidUpdate() {
    if (this.state.counter <= 0) {
      clearInterval(this.timer);
      this.timer = null;
      this.setState({ canResend: true });
    }
    if (this.props.open && this.state.firstCounter) {
      this.resetResendFlag();
      this.setState({ firstCounter: false });
    }
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  handleCloseOtpModal = () => {
    this.setState({ showOtpValidationModal: false, counter: this.RESEND_FLAG_SECONDS_DELAY, canResend: false });
    clearInterval(this.timer);
    this.resetResendFlag();
  };

  render() {
    const {
      open,
      debtor,
      classes,
      handleCloseOtpModal,
      cosigner,
      otpValidatorDebtor,
      isRemoteTeleshopping,
      mobile,
      loading,
    } = this.props;
    let otpMobile = debtor ? debtor.mobile : '';
    let signer = 'deudor';
    if (isRemoteTeleshopping) {
      otpMobile = mobile;
    } else {
      if (cosigner && otpValidatorDebtor) {
        otpMobile = cosigner.mobile;
        signer = "codeudor";
      }
    }
    return (
      <CustomDialog
        open={open}
        title={`Firma ${signer}`}
        onClose={handleCloseOtpModal}
        disableBackdropClick={false}
      >
        <Formik
          initialValues={{ otp: '' }}
          onSubmit={async values => this._handleValidateOtp(values.otp, otpMobile, signer)}
          validationSchema={validationSchema}
          validateOnChange={false}
          validateOnBlur={false}
          render={({ values, handleChange, handleSubmit }) => {
            return (
              <div className={classes.otpbody}>
               <p className={classes.body1_}>
                  <p>
                    {`Ingresa el código de 4 dígitos enviado por SMS al celular ${otpMobile}`}
                  </p>
                </p>
                <Field
                  name="otp"
                  render={({ field, form }) => {
                    return (
                      <OtpInput
                        numInputs={4}
                        isInputNum={true}
                        onChange={otp => this._handleChange(otp, handleChange)}
                        value={values.otp}
                        field={field}
                        form={form}
                        shouldAutoFocus={true}
                        inputStyle={classNames(classes.otpInputStyle)}
                      />
                    );
                  }}
                />
                 {!this.state.canResend && (
                    <div className={classes.item} variant="caption">
                      Si el mensaje no te ha llegado en 2 minutos, puedes volver a enviar
                      el mensaje de texto con el código verificación
                    </div>
                  )}
                <div className={classes.actionContainer}>
                  <span
                    id="PhoneVerificationDialog_span_resendVerification"
                    onClick={() => this._handleForward(otpMobile)}
                    className={classes.pointer}
                  >
                    <Timer counter={this.state.counter} loading={loading} />
                  </span>
                </div>
                <PrimaryButton
                  onClick={handleSubmit}
                  className={classes.button}
                  disabled={values.otp.length !== 4 || loading}
                >
                  Validar
                </PrimaryButton>
              </div>
            );
          }}
        />
      </CustomDialog>
    );
  }
}

OTPV2Modal.propTypes = {
  open: PropTypes.bool.isRequired,
  transaccionId: PropTypes.number,
  debtor: PropTypes.object,
  handleCloseOtpModal: PropTypes.func.isRequired,
  handleOpenOtpModal: PropTypes.func.isRequired,
  validateOtpV2: PropTypes.func.isRequired,
  reSendOtpV2: PropTypes.func.isRequired,
  otpValidatorDebtor: PropTypes.bool,
  otpValidatorCosigner: PropTypes.bool,
  cosigner: PropTypes.object,
  isRemoteTeleshopping: PropTypes.bool,
  mobile: PropTypes.string,
  validateOtpV2Teleshopping: PropTypes.func.isRequired,
  reSendOtpV2Teleshopping: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const styles = theme => ({
  otpInputStyle: {
    width: '3rem !important',
    margin: '20px 1rem',
    borderBottom: '2px solid #707070',
    color: '#2C3C47',
    fontFamily: 'Roboto',
    fontSize: '50px'
  },
  otpbody: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: `${theme.spacing.unit * 7.375}px !important`,
    color: '#707070'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  body1_: {
    color: theme.typography.color.main,
    fontFamily: theme.typography.font.main,
    fontSize: '16px',
    fontWeight: 400,
    letterSpacing: '0.2px',
    lineHeight: '28px',
    margin: 0,
    textAlign: 'center',
  },
  pointer: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  actionContainer: {
    textAlign: 'center',
    width: '100%',
    marginTop: 24,
    marginBottom: 24,
    fontSize: '22px',
  },
});

export default withStyles(styles)(OTPV2Modal);
