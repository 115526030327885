import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  pointOfSale: Yup.number().required('El campo es requerido'),
  clerkId: Yup.number().required('El campo es requerido'),
  sellingChannel: Yup.number().required('El campo es requerido'),
  currentDate: Yup.string(),
  saleDate: Yup.string()
    .required('La fecha no debe superar los 6 meses de antigüedad.')
    .nullable()
});
