import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classnames from 'classnames';
import SinisterFilters from './SinisterFilters';
import parseQuerystring from '../../../helpers/parseQuerystring';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import SinisterDetail from './modal/SinisterDetailForm';
import { history } from '../../../helpers/history';
import RequestService from '../../../services/request_service';

const requestServiceNode = new RequestService('node');

class SinisterList extends React.Component {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    sinisterDetail: null
  };

  async componentDidMount() {
    this.fetchItems();

    const {
      location: { state }
    } = this.props;

    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const item = await this.fetchItem(state.entityId);
        if (item) {
          this.setState({ sinisterDetail: item });
        }
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (
      (prevProps.requestRevokeSinisterSend === true &&
        this.props.requestRevokeSinisterSend === false) ||
      (prevProps.requestApproveSinisterSend === true &&
        this.props.requestApproveSinisterSend === false)
    ) {
      this.handleCloseForm();
    }

    const {
      location: { state }
    } = this.props;

    if (state) {
      history.replace({
        pathname: window.location.pathname,
        search: window.location.search,
        state: null
      });
      if (state.entityId) {
        const item = await this.fetchItem(state.entityId);
        if (item) {
          this.setState({ sinisterDetail: item });
        }
      }
    }
  }

  fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchSinisters({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  fetchItem = async id => {
    try {
      const payload = await requestServiceNode.get(
        'sinisters',
        {},
        true
      );
      return payload.data[0];
    } catch (errors) {
      return null;
    }
  };

  handleRowClick = sinisterDetail => {
    this.setState({ sinisterDetail });
  };

  handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this.fetchItems({
      ...query,
      page: page + 1
    });
  };

  handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this.fetchItems);
  };

  handleCloseForm = () => this.setState({ sinisterDetail: null });

  handleRevoke = params => {
    const query = parseQuerystring(this.props) || {};
    this.props.revokeSinister(params, {
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  handleApprove = params => {
    const query = parseQuerystring(this.props) || {};
    this.props.approveSinister(params, {
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  render() {
    const {
      classes,
      sinisters = [],
      meta = {},
      requestSend,
      sinisterRegisterCauses
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            Siniestros
          </h1>
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <SinisterFilters
              onChangeFilter={this.fetchItems}
              sinisterRegisterCauses={sinisterRegisterCauses}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Siniestro'}
            columns={columns}
            data={mapData(sinisters)}
            onChangePage={this.handleChangePage}
            onChangePageRowsPerPage={this.handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={this.handleRowClick}
            totalCount={0}
            loading={requestSend}
            withNoCountPagination={true}
            nextPage={meta.nextPage}
          />
        </div>
        {!!this.state.sinisterDetail && (
          <SinisterDetail
            sinisterDetail={this.state.sinisterDetail}
            canApproveSinister={this.props.canApproveSinister}
            canRevokeSinister={this.props.canRevokeSinister}
            onClose={this.handleCloseForm}
            onRevoke={this.handleRevoke}
            onApprove={this.handleApprove}
            isSaving={this.props.requestApproveSinisterSend}
            isRevoking={this.props.requestRevokeSinisterSend}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

SinisterList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  sinisters: PropTypes.array.isRequired,
  fetchSinisters: PropTypes.func.isRequired,
  meta: PropTypes.object
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(SinisterList);
