import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';
import _every from 'lodash/every';
import _find from 'lodash/find';
import { Grid, TextField, withStyles } from '@material-ui/core';

import Movements from '../../shared/Movements/Movements';
import CustomDialog from '../../shared/Modals/CustomDialog';
import { MANUAL_QUOTA_ACTIONS, MANUAL_QUOTA_STATUS } from '../../../constants/enums';
import SimpleTable from '../../shared/Tables/SimpleTable';
import DateFieldInput from '../../shared/Inputs/DateField';



class ManualQuotaRevision extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comment: '',
      initialDate: null,
      finalDate: null,
      revoked: null,
      api_errors: null
    };
  }

  _handleDeactivate = () => {
    const { manualQuota } = this.props;
    const testBody = {
      manual_quotum: {
        comment: this.state.comment
      },
      id: manualQuota.id
    };
    this.props.manualQuotaDeactivate(testBody).then(r => {
      if (r.success) {
        this.props.reFetch();
        this._handleClose();
      }
    });
  };

  _handleSave = revoked => {
    const { manualQuota } = this.props;
    this.setState({ revoked: revoked });
    const body = {
      manual_quotum: {
        ...this.state
      },
      id: manualQuota.id
    };
    const action = revoked
      ? 'revoke'
      : manualQuota.status == '1'
        ? 'approve'
        : 'review';

    this.props.manualQuotaRevision(body, action).then(r => {
      if (r.success) {
        if (this.props.reFetch) {
          this.props.reFetch();
        }
        this._handleClose();
      }
    });
  };

  _handleClose = () => {
    if (!this.props.requestSend) {
      this.setState({
        comment: '',
        initialDate: null,
        finalDate: null,
        revoked: null
      });
      this.props.onClose();
    }
  };

  _handleChange = e => {
    const { name, value } = e.target;
    if (name == 'initialDate' || name == 'finalDate') {
      value && this.setState({ [name]: value, api_errors: null });
      !value && this.setState({ [name]: null, api_errors: null });
    } else {
      this.setState({ [name]: value, api_errors: null });
    }
    this.setState({ [name]: value });
  };

  _getContract = manualQuota => {
    if (!manualQuota) return [];
    const mapManualQuota = {
      key: manualQuota.id,
      name:
        _get(manualQuota, 'contract.client.name', '--') +
        ' ' +
        _get(manualQuota, 'contract.client.lastName', ''),
      contract: _get(manualQuota, 'contract.id', '--') || '--',
      value: manualQuota.value || '--'
    };
    return [mapManualQuota];
  };

  _disableSubmit = () => {
    const { status } = this.props.manualQuota;
    if (status == MANUAL_QUOTA_STATUS.registered || status == MANUAL_QUOTA_STATUS.approved) return false;
    const cantBeEmpty = {
      ...this.state,
      revoked: 'no-check',
      api_errors: 'no-check',
      comment: 'no-check'
    };
    if (!_every(cantBeEmpty, e => !!e)) return true;
    return false;
  };

  _modalProps = (manualQuota, action) => {
    const status = manualQuota.status;
    if (action == 'Ver') {
      return {
        onConfirm: () => null,
        title: `Solicitud de Cupo Manual`
      };
    }
    if (status != MANUAL_QUOTA_STATUS.registered && status != MANUAL_QUOTA_STATUS.reviewed) {
      return {
        onConfirm: () => this._handleDeactivate(),
        title: `Solicitud de Cupo Manual`,
        buttonText:
          manualQuota.active && this.props.canDeactivateManualQuota
            ? 'Desactivar'
            : null
      };
    }
    return {
      onConfirm: () => this._handleSave(false),
      title: `Solicitud de Cupo Manual`,
      buttonText: MANUAL_QUOTA_ACTIONS[manualQuota.status].action,
      buttonSecondaryText: this.props.canRevokeManualQuota ? 'RECHAZAR' : null,
      onConfirmSecondary: () => this._handleSave(true)
    };
  };

  _showComment = action => {
    if (action == 'Ver' || !action) return false;
    return true;
  };

  _contextText = manualQuota => {
    if (!manualQuota) return '';
    if (manualQuota.status == MANUAL_QUOTA_STATUS.approved) {
      if (!manualQuota.active) return `Inactivo`;
      return `Solicitud legalizada, cupo activo.`;
    }
    return `Se solicita ${MANUAL_QUOTA_ACTIONS[manualQuota.status].inf}.`;
  };

  _actionPermissions = () => {
    const {
      manualQuota,
      canReviewManualQuota,
      canApproveManualQuota,
      canDeactivateManualQuota,
      canListManualQuota
    } = this.props;
    if (manualQuota.status == MANUAL_QUOTA_STATUS.registered) {
      if (canReviewManualQuota) return 'Revisar';
    }
    if (manualQuota.status == MANUAL_QUOTA_STATUS.reviewed) {
      if (canApproveManualQuota) return 'Aprobar';
    }
    if (manualQuota.status == MANUAL_QUOTA_STATUS.approved) {
      if (canDeactivateManualQuota) return 'Desactivar';
    }
    if (canListManualQuota) return 'Ver';
    return false;
  };

  _commentsRevokeDeactivate = (movements, type) => {
    const { classes } = this.props;
    const text = _get(_find(movements, { status: type }), 'comment', '');
    if (!text) return null;
    return (
      <p className={classes.titleContainer}>
        {`Comentario ${type == MANUAL_QUOTA_STATUS.revoked ? 'de rechazo' : 'desactivación'}: `}
        <a className={classes.subText}>{text}</a>
      </p>
    );
  };

  _getDescription = (status) => {
    switch (status) {
      case 'registered':
        return 'Solicitud';
      case 'reviewed':
        return 'Revisión';
      case 'revoked':
        return 'Rechazo';
      case 'approved':
        return 'Aprobación';
      case 'deactivated':
        return 'Desactivación';
      default:
        return '';
    }
  };

  render() {
    const { classes, manualQuota, requestSend } = this.props;
    const { comment, revoked } = this.state;
    if (!manualQuota) return null;
    if (!MANUAL_QUOTA_ACTIONS[manualQuota.status]) return null;
    const action = this._actionPermissions();
    if (!action) return null;
    return (
      <div>
        <CustomDialog
          open={true}
          loading={requestSend && !revoked}
          loadingSecondary={requestSend && revoked}
          disabled={requestSend || this._disableSubmit()}
          disableBackdropClick={false}
          headerSecondary={<div />}
          onClose={this._handleClose}
          {...this._modalProps(manualQuota, action)}
          classes={{
            root: classes.dialogRoot
          }}
        >
          <Grid container spacing={16}>
            {manualQuota.movements && (
              <Movements movements={manualQuota.movements} />
            )}
            <Grid item xs={12}>
              <SimpleTable
                headers={[
                  {
                    name: 'name',
                    title: 'Nombre Titular'
                  },
                  {
                    name: 'contract',
                    title: '#Contrato'
                  },
                  {
                    name: 'value',
                    title: 'Valor solicitado'
                  }
                ]}
                rows={this._getContract(manualQuota)}
              />
            </Grid>
            <div className={classes.textContainer}>
              <p className={classes.titleContainer}>
                {'Motivo: '}
                <a className={classes.subText}>{manualQuota.observations}</a>
              </p>
              {
                manualQuota.movements.map(mov => {
                  return mov.status !== 'registered' ? (
                    <p className={classes.titleContainer} key={mov.id}>
                      {`${this._getDescription(mov.status)}: `}
                      <a className={classes.subText}>{mov.comment}</a>
                    </p>
                  ) : undefined;
                })
              }
            </div>
            {manualQuota.status == 'Rechazada' &&
              this._commentsRevokeDeactivate(
                manualQuota.movements,
                'revoked'
              )}
            {manualQuota.status == 'Desactivada' &&
              this._commentsRevokeDeactivate(
                manualQuota.movements,
                'deactivated'
              )}
            {action == 'Aprobar' && (
              <Fragment>
                <Grid item xs={6} className={classes.topInput}>
                  <DateFieldInput
                    name={'initialDate'}
                    value={this.state.initialDate}
                    onChange={this._handleChange}
                    disabled={requestSend}
                    maxDate={this.state.finalDate}
                    label={'Fecha de Inicio*'}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6} className={classes.topInput}>
                  <DateFieldInput
                    name={'finalDate'}
                    value={this.state.finalDate}
                    onChange={this._handleChange}
                    disabled={requestSend}
                    minDate={this.state.initialDate}
                    label={'Fecha de Finalización*'}
                    fullWidth
                  />
                </Grid>
              </Fragment>
            )}
            {this._showComment(action) && (
              <Grid item xs={12}>
                <TextField
                  label={manualQuota.status == MANUAL_QUOTA_STATUS.approved ? 'Motivo Desactivación' : 'Agregar Comentario'}
                  name="comment"
                  margin="normal"
                  variant="outlined"
                  onChange={this._handleChange}
                  value={comment}
                  disabled={requestSend}
                  fullWidth
                  multiline={true}
                  rows={2}
                  inputProps={{ maxLength: 199 }}
                />
              </Grid>
            )}
          </Grid>
        </CustomDialog>
      </div>
    );
  }
}

const styles = theme => ({
  activeContainer: {
    padding: theme.spacing.unit * 2
  },
  dialogRoot: {
    width: 800
  },
  topInput: {
    marginTop: theme.spacing.unit * 2
  },
  subText: {
    color: theme.typography.color.light,
    paddingLeft: theme.spacing.unit / 2,
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    wordBreak: 'break-all'
  },
  titleContainer: {
    color: theme.typography.color.main,
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily,
    fontSize: '14px',
    paddingTop: '6px',
    margin: `0px ${theme.spacing.unit * 3}px `,
    marginBottom: '3px',
    overflowWrap: 'anywhere'
  },
  textContainer: {
    display: 'block',
    width: '100%'
  },
  heading: {
    color: 'rgba(12,31,44,0.87)',
    fontSize: theme.typography.pxToRem(15),
    fontWeight: 'bold',
    fontFamily: theme.typography.fontFamily
  },
  comments: {
    marginTop: theme.spacing.unit * 2
  }
});

ManualQuotaRevision.propTypes = {
  open: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(ManualQuotaRevision);
