import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import FinancialDetailsFilters from './FinancialDetailsFilters';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import FinancialDetailEditCreate from './Modal/FinancialDetailEditCreate';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

class FinancialDetailsList extends Component {
  state = {
    isOpenedFinancialDetailFormModal: false,
    detailedPlan: {
      isNew: true
    },
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE
  };

  componentDidMount() {
    const {
      fetchOrgData,
      financialPlanNames,
      categoryNames,
      contractTypeNames,
      locationNames,
      saleChannelNames,
      stratumNames
    } = this.props;
    if (
      !financialPlanNames ||
      !categoryNames ||
      !contractTypeNames ||
      !locationNames ||
      !saleChannelNames ||
      !stratumNames
    ) {
      fetchOrgData();
    }
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.detailedPlanSaved !== this.props.detailedPlanSaved &&
      this.props.detailedPlanSaved
    ) {
      this.setState({ isOpenedFinancialDetailFormModal: false });
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchDetailedPlans({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _toFinancialDetail = detailedPlan => {
    const { canUpdateFinancingPlanToCategories } = this.props;
    if (canUpdateFinancingPlanToCategories) {
      this.setState({
        detailedPlan: {
          ...detailedPlan,
          isNew: false
        },
        isOpenedFinancialDetailFormModal: true
      });
    }
  };

  _newFinancialDetail = () => {
    this.setState({
      isOpenedFinancialDetailFormModal: true,
      detailedPlan: {
        isNew: true
      }
    });
  };

  _handleCloseCreateFinancialDetailFormModal = () => {
    this.setState({
      isOpenedFinancialDetailFormModal: false
    });
  };

  _handleSave = params => {
    const query = parseQuerystring(this.props) || {};
    if (params.isNew) {
      this.props.createFinancialPlanDetail(params, {
        ...query,
        page: query.page || 1,
        perPage: this.state.rowsPerPage
      });
    } else {
      this.props.updateFinancialPlanDetail(params, {
        ...query,
        page: query.page || 1,
        perPage: this.state.rowsPerPage
      });
    }
  };

  render() {
    const {
      classes,
      detailedPlans = [],
      meta = {},
      financialPlanNames,
      categoryNames,
      detailedPlansLoading = true,
      orgDataLoading = true,
      canCreateFinancingPlanToCategories = false
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Detalle Planes de Financiación'}
          </h1>
          {canCreateFinancingPlanToCategories && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={() => this._newFinancialDetail()}
            >
              {'Asociar Plan'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <FinancialDetailsFilters
              onChangeFilter={this._fetchItems}
              categories={categoryNames}
              financialPlans={financialPlanNames}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Asociación'}
            columns={columns}
            data={mapData(detailedPlans)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={this._toFinancialDetail}
            totalCount={(meta && meta.totalCount) || 0}
            loading={detailedPlansLoading || orgDataLoading}
          />
        </div>
        {this.state.isOpenedFinancialDetailFormModal && (
          <FinancialDetailEditCreate
            detailedPlan={this.state.detailedPlan}
            open={this.state.isOpenedFinancialDetailFormModal}
            loading={this.props.requestFinancialDetailsUpdateSend}
            categoryNames={this.props.categoryNames}
            financialPlanNames={this.props.financialPlanNames}
            contractTypeNames={this.props.contractTypeNames}
            locationNames={this.props.locationNames}
            saleChannelNames={this.props.saleChannelNames}
            stratumNames={this.props.stratumNames}
            apiErrors={this.props.requestErrorsOnFinancialDetailsUpdate}
            onClose={this._handleCloseCreateFinancialDetailFormModal}
            onSave={this._handleSave}
          />
        )}
      </div>
    );
  }
}

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle
  ),
  { withTheme: true }
)(FinancialDetailsList);
