import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import Filters from './Filters';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import Table from '../../shared/Table/Table';
import classnames from 'classnames';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import CategoryFormModal from './Modal/CategoryFormModal';

class CategoryList extends Component {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    category: {
      isNew: true
    },
    isOpenedCategoryFormModal: false,
    filterParams: {}
  };

  componentDidMount() {
    if (!this.props.categories) {
      this.props.fetchOrgData();
    }
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.categorySaved !== this.props.categorySaved &&
      this.props.categorySaved
    ) {
      this.setState({ isOpenedCategoryFormModal: false });
    }
  }

  _fetchItems = params => {
    this.setState({ filterParams: params });
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchCategories({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleRowClick = (category, canUpdateCategories) => {
    if (canUpdateCategories) {
      this.setState({
        category: {
          ...this.state.category,
          ...category,
          isNew: false
        },
        isOpenedCategoryFormModal: true
      });
    }
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleOpenCreateCategoryModal = () => {
    this.setState({
      isOpenedCategoryFormModal: true,
      category: {
        isNew: true
      }
    });
  };

  _handleCloseCreateCategoryModal = () => {
    this.setState({
      isOpenedCategoryFormModal: false
    });
  };

  _handleSave = category => {
    const query = parseQuerystring(this.props) || {};
    if (category.isNew) {
      this.props.createCategory(
        {
          ...category
        },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      );
    } else {
      this.props.updateCategory(
        {
          ...category
        },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      );
    }
  };

  _handleDownloadClick = params => () => {
    this.props.exportToXlsx(params);
  };

  render() {
    const {
      classes,
      categories = [],
      meta = {},
      requestFetchCategoriesSend = true,
      canCreateCategories,
      canUpdateCategories,
      canExportCategories,
      canUpdateCategoriesNameInSupplierProposalModuleField,
      requestExportToXlsxSend
    } = this.props;

    const { page } = parseQuerystring(this.props) || {};
    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Categorías'}
          </h1>
          {canCreateCategories && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateCategoryModal}
            >
              {'Crear Categoría'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <Filters onChangeFilter={this._fetchItems} />
          </div>
        </div>
        <div>
          <Table
            name={'Categoría'}
            columns={columns}
            data={mapData(categories)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props =>
              this._handleRowClick(props, canUpdateCategories)
            }
            totalCount={(meta && meta.totalCount) || 0}
            loading={requestFetchCategoriesSend}
            withDownload={canExportCategories}
            onDownloadIconClick={this._handleDownloadClick(
              this.state.filterParams
            )}
            loadingDownload={requestExportToXlsxSend}
          />
        </div>
        {this.state.isOpenedCategoryFormModal && (
          <CategoryFormModal
            allCategories={this.props.allCategories.filter(
              c => c.depth === 0 && c.active === true
            )}
            onClose={this._handleCloseCreateCategoryModal}
            onSave={this._handleSave}
            errors={this.props.requestErrorsOnSaveCategory}
            isSubmitting={this.props.requestSaveCategorySend}
            category={this.state.category}
            canUpdateCategoriesNameInSupplierProposalModuleField={
              canUpdateCategoriesNameInSupplierProposalModuleField
            }
            canSetMaxItemsPerCategory={this.props.canSetMaxItemsPerCategory}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

CategoryList.propTypes = {
  classes: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object,
  canUpdateCategories: PropTypes.bool,
  canCreateCategories: PropTypes.bool
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(CategoryList);
