import {
  LIST_REVISIONS_SUCCESS,
  DOCUMENT_REVISION_PERSIST,
  DOCUMENT_REVISION_CLEAR,
  SET_DOCUMENT_REVISION_DATA,
  SET_DOCUMENT_REVISION_IMAGE_KEY,
  CHECK_CLIENT_VALIDATION,
  TOGGLE_BASE_MODAL,
  STEP_SUCCESS_DOCUMENT_REVISION,
  SET_NEXT_STEP,
  STEP_ERROR_DOCUMENT_REVISION,
  STEP_LOADING_DOCUMENT_REVISION
} from '../constants/action_constants';
import digitalSaleDocumentRevisionsFiltersSanitizer from '../helpers/sanitizers/digitalSaleDocumentRevisionFiltersSanitizer';
import RequestService from '../services/request_service';
import { sendRequest, completeRequest } from './request_state';
const requestService = new RequestService();
import { history } from '../helpers/history';
import { stringifyQuery } from '../helpers/parseQuerystring';
import AxiosRequest from '../services/axios_request';
import _get from 'lodash/get';
const axiosRequest = new AxiosRequest();
const CACHE_KEY = 'digitalSalesDocumentRevisions';

//* PURE ACTION FUNCTIONS (This ones call the reducer)
function success(items, pagina) {
  return {
    type: LIST_REVISIONS_SUCCESS,
    data: items,
    meta: pagina
  };
}

function setDocumentRevisionData(data) {
  return {
    type: SET_DOCUMENT_REVISION_DATA,
    data: data
  };
}

function setDocumentRevisionImageKey(image_key, image_url) {
  return {
    type: SET_DOCUMENT_REVISION_IMAGE_KEY,
    data: image_url,
    dataKey: image_key
  };
}

function checkClientValidation(stepName, stepValidated) {
  return {
    type: CHECK_CLIENT_VALIDATION,
    stepName: stepName,
    stepValue: stepValidated
  };
}

export const fetchDigitalSalesDocumentRevision = (
  params = {}
) => async dispatch => {
  dispatch(sendRequest());
  history.replace(`/digital-sales-document-revision?${stringifyQuery(params)}`);
  try {
    const payload = await requestService.get(
      'digital_sales_document_revision',
      digitalSaleDocumentRevisionsFiltersSanitizer(params)
    );
    dispatch(success(payload.data, payload.meta));
  } catch (errors) {
    // console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

export const getDocumentRevisionData = docRevisionId => async (
  dispatch,
  getState
) => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.get(
      `digital_sales_document_revision/${docRevisionId}`
    );
    let process = '';
    let pushTo = '';
    if (payload.data.type) {
      switch (payload.data.type) {
        case 1:
          process = 'digitalSalesDocumentRevisionPromissory';
          pushTo = '/digital-sales-document-revision/client';
          break;
        case 2:
          process = 'digitalSalesDocumentRevisionSale';
          pushTo = '/digital-sales-document-revision/sale';
          break;
        case 3:
          process = 'digitalSalesDocumentRevision';
          pushTo = '/digital-sales-document-revision/client';
          break;
        default:
          break;
      }
    }
    dispatch(setNextStep(process));
    dispatch(setDocumentRevisionData(payload.data, payload.meta));
    cacheDocRevision(getState());
    history.push(pushTo);
  } catch (errors) {
    console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

export const getDocumentRevisionImageBase64 = (
  docRevisionId,
  imageKey
) => async (dispatch, getState) => {
  dispatch(sendRequest());
  try {
    const payload = await requestService.get(
      `digital_sales_document_revision/${docRevisionId}/image/${imageKey}`
    );
    if (payload.data.valid) {
      dispatch(
        setDocumentRevisionImageKey(imageKey, payload.data.image_base_64)
      );
    }
  } catch (errors) {
    console.log(errors);
  } finally {
    dispatch(completeRequest());
  }
};

export const getNextDocumentRevisionData = () => async (dispatch, getState) => {
  dispatch(sendRequest());
  dispatch(stepLoading());
  try {
    const payload = await requestService.get(
      'digital_sales_document_revision/next'
    );
    let process = '';
    let pushTo = '';
    if (payload.data.type) {
      switch (payload.data.type) {
        case 1:
          process = 'digitalSalesDocumentRevisionPromissory';
          pushTo = '/digital-sales-document-revision/client';
          break;
        case 2:
          process = 'digitalSalesDocumentRevisionSale';
          pushTo = '/digital-sales-document-revision/sale';
          break;
        case 3:
          process = 'digitalSalesDocumentRevision';
          pushTo = '/digital-sales-document-revision/client';
          break;
        default:
          break;
      }
    }
    dispatch(stepSuccess());
    dispatch(setNextStep(process));
    dispatch(setDocumentRevisionData(payload.data, payload.meta));
    cacheDocRevision(getState());
    history.push(pushTo);
  }catch (error) {
    dispatch(stepSuccess());
    history.push('/digital-sales-document-revision');
  } finally {
    dispatch(completeRequest());
  }
};

export function digitalSalesDocumentRevisionCheckClient(
  docRevisionId,
  params = {},
  route,
  process
) {
  return dispatch => {
    dispatch(stepLoading());
    return requestService
      .post(
        `digital_sales_document_revision/${docRevisionId}/client`,
        params,
        null
      )
      .then(
        payload => {
          if (payload.data.valid) {
            const stepName = `${params.step}_validated`;
            dispatch(checkClientValidation(stepName, payload.data[stepName]));
            dispatch(stepSuccess());
            dispatch(setNextStep(process));
            history.push(route);
          }
        },
        error => {
          error &&
            error.json().then(e => {
              dispatch(stepError(e.errors));
              _get(e, 'errors.base') &&
                dispatch(toggleBaseErrorModal(true, e.errors.base[0], ''));
            });
        }
      );
  };
}

export const clearDocumentRevision = () => dispatch => {
  localStorage.removeItem(CACHE_KEY);
  localStorage.removeItem('stepper');
  dispatch({ type: DOCUMENT_REVISION_CLEAR });
};

export const persistDocumentRevision = () => dispatch => {
  const cache = localStorage.getItem(CACHE_KEY) || null;
  if (cache) {
    const data = JSON.parse(cache);
    dispatch({ type: DOCUMENT_REVISION_PERSIST, data });
  }
};

export const cacheDocRevision = state => {
  const {
    digitalSalesDocumentRevisions: {
      face_url_gesture,
      face_url_validation,
      frontal_document,
      back_document,
      pdf_promissory_url,
      voucher_sale_url,
      pdf_vocuher_url,
      ...digitalSalesDocumentRevisions
    },
    stepper
  } = state;
  localStorage.setItem(
    CACHE_KEY,
    JSON.stringify(digitalSalesDocumentRevisions)
  );
  localStorage.setItem('stepper', JSON.stringify(stepper));
};

export function toggleBaseErrorModal(open, text, title, route) {
  return {
    type: TOGGLE_BASE_MODAL,
    open,
    text,
    title,
    route
  };
}

function stepSuccess(data) {
  return (dispatch, getState) => {
    dispatch({
      type: STEP_SUCCESS_DOCUMENT_REVISION,
      data
    });
    cacheDocRevision(getState());
  };
}

export function setNextStep(process, nextStep) {
  return (dispatch, getState) => {
    dispatch({
      type: SET_NEXT_STEP,
      process,
      nextStep,
      nextStepTitle: process
    });
    cacheDocRevision(getState());
  };
}

function stepError(errors) {
  return {
    type: STEP_ERROR_DOCUMENT_REVISION,
    errors
  };
}

function stepLoading() {
  return {
    type: STEP_LOADING_DOCUMENT_REVISION
  };
}
