import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import Filters from './Filters';
import ExtraQuotumFormModal from './modals/ExtraQuotumFormModal';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';
import { history } from '../../../helpers/history';

const DEFAULT_CONTRACT_TYPE = 1; //Tipo residencial
const DEFAULT_OPTION = 'V'; //Tipo residencial

const NEW_EXTRA_QUOTUM = {
  isNew: true,
  contractTypeId: DEFAULT_CONTRACT_TYPE,
  option: DEFAULT_OPTION,
  suppliers: []
};

class ExtraQuotumList extends PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    isOpenedCreateExtraQuotumModal: false,
    extraQuotum: {
      ...NEW_EXTRA_QUOTUM
    }
  };

  componentDidMount() {
    if (!this.props.suppliers || !this.props.categories) {
      this.props.fetchOrgData();
    }
    this.props.fetchGeographicLocations();
    this.props.fetchContractStrata();
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.extraQuotumSaved !== this.props.extraQuotumSaved &&
      this.props.extraQuotumSaved
    ) {
      this.setState({ isOpenedCreateExtraQuotumModal: false });
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};
    this.props.fetchExtraQuota({
      ...query,
      page: query.page || 1,
      per_page: this.state.rowsPerPage
    });
  };

  _handleRowClick = (extraQuotum, canUpdateExtraQuota) => {
    if (canUpdateExtraQuota) {
      this.setState({
        extraQuotum: {
          ...this.state.extraQuotum,
          ...extraQuotum,
          isNew: false
        },
        isOpenedCreateExtraQuotumModal: true
      });
    }
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleOpenCreateExtraQuotumModal = () =>
    this.setState({
      isOpenedCreateExtraQuotumModal: true,
      extraQuotum: {
        ...NEW_EXTRA_QUOTUM
      }
    });

  _handleCloseCreateExtraQuotumModal = () =>
    this.setState({ isOpenedCreateExtraQuotumModal: false });

  _handleSave = extraQuotum => {
    const query = parseQuerystring(this.props) || {};
    if (extraQuotum.isNew) {
      this.props.createExtraQuotum(
        { extra_quotum: { ...extraQuotum } },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      );
    } else {
      this.props.updateExtraQuotum(
        { extra_quotum: { ...extraQuotum } },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      );
    }
  };

  render() {
    const {
      classes,
      extraQuota = [],
      metadata = {},
      requestExtraQuotaListSend = true,
      canCreateExtraQuota,
      canUpdateExtraQuota
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Extra Cupos'}
          </h1>
          {canCreateExtraQuota && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateExtraQuotumModal}
            >
              {'Crear Extra Cupo'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <Filters
              onChangeFilter={this._fetchItems}
              suppliers={this.props.suppliers}
              categories={this.props.categories}
              geographicLocations={this.props.geographicLocations}
              contractStrata={this.props.contractStrata}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Extra Cupo'}
            columns={columns}
            data={mapData(extraQuota)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props =>
              this._handleRowClick(props, canUpdateExtraQuota)
            }
            totalCount={(metadata && metadata.total_count) || 0}
            loading={requestExtraQuotaListSend}
          />
        </div>
        {this.state.isOpenedCreateExtraQuotumModal && (
          <ExtraQuotumFormModal
            suppliers={this.props.suppliers}
            categories={this.props.categories}
            geographicLocations={this.props.geographicLocations}
            contractStrata={this.props.contractStrata}
            onClose={this._handleCloseCreateExtraQuotumModal}
            onSave={this._handleSave}
            errors={this.props.requestErrorsOnSaveExtraQuotum}
            isSubmitting={this.props.requestSaveExtraQuotumSend}
            extraQuotum={this.state.extraQuotum}
            companyName={this.props.companyName}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

ExtraQuotumList.propTypes = {
  metadata: PropTypes.object,
  fetchExtraQuota: PropTypes.func.isRequired,
  extraQuota: PropTypes.array.isRequired,
  suppliers: PropTypes.array,
  canCreateExtraQuota: PropTypes.bool,
  canUpdateExtraQuota: PropTypes.bool
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(ExtraQuotumList);
