import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import parseQuerystring from '../../../helpers/parseQuerystring';
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import Table from '../../shared/Table/Table';
import { columns, mapData } from './TableMetadata';
import combineStyles from '../../../helpers/combineStyles';
import LayoutStyles from '../../../styles/layout';
import ButtonStyles from '../../../styles/button';
import TypographyStyles from '../../../styles/typography';
import SpacingStyles from '../../../styles/helpers/spacing';
import ListsStyle from '../__styles__/Lists.style';
import Filters from './Filters';
import CampaignFormModal from './modals/CampaignFormModal';
import { DEFAULT_ROWS_PER_PAGE } from '../../../constants/request_constants';

const NEW_CAMPAIGN = {
  isNew: true,
  suppliers: [],
  geographicLocations: [],
  categories: [],
  saleChannels: [],
  contractStratus: []
}

class CampaignList extends PureComponent {
  state = {
    rowsPerPage: localStorage.getItem('BrillaRowsPerPage')
      ? parseInt(localStorage.getItem('BrillaRowsPerPage'), 10)
      : DEFAULT_ROWS_PER_PAGE,
    isOpenedCreateCampaignModal: false,
    campaign: {
      ...NEW_CAMPAIGN
    }
  };

  componentDidMount() {
    if (!this.props.suppliers || !this.props.categories) {
      this.props.fetchOrgData();
    }
    this.props.fetchGeographicLocations();
    this.props.fetchContractStrata();
    this._fetchItems();
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.campaignSaved !== this.props.campaignSaved &&
      this.props.campaignSaved
    ) {
      this.setState({ isOpenedCreateCampaignModal: false });
    }
  }

  _fetchItems = params => {
    const query = params || parseQuerystring(this.props) || {};

    this.props.fetchCampaign({
      ...query,
      page: query.page || 1,
      perPage: this.state.rowsPerPage
    });
  };

  _handleRowClick = (campaign, canUpdateCampaign) => {
    if (canUpdateCampaign) {
      this.setState({
        campaign: {
          ...this.state.campaign,
          ...campaign,
          isNew: false
        },
        isOpenedCreateCampaignModal: true
      });
    }
  };

  _handleChangePage = page => {
    const query = parseQuerystring(this.props) || {};
    this._fetchItems({
      ...query,
      page: page + 1
    });
  };

  _handleChangeRowsPerPage = rowsPerPage => {
    localStorage.setItem('BrillaRowsPerPage', rowsPerPage);
    this.setState({ rowsPerPage: rowsPerPage, page: 1 }, this._fetchItems);
  };

  _handleOpenCreateCampaignModal = () =>
    this.setState({
      isOpenedCreateCampaignModal: true,
      campaign: {
        ...NEW_CAMPAIGN
      }
    });

  _handleCloseCreateCampaignModal = () =>
    this.setState({ isOpenedCreateCampaignModal: false });

  _handleSave = campaign => {
    const query = parseQuerystring(this.props) || {};
    if (campaign.isNew) {
      this.props.createCampaign(
        { campaign: { ...campaign } },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      );
    } else {
      this.props.updateCampaign(
        { campaign: { ...campaign } },
        {
          ...query,
          page: query.page || 1,
          per_page: this.state.rowsPerPage
        }
      );
    }
  };

  _handleDelete = campaignId => {
    const query = parseQuerystring(this.props) || {};
    this.props.deleteCampaign(
      { id: campaignId  },
      {
        ...query,
        page: query.page || 1,
        per_page: this.state.rowsPerPage
      }
    );
  };

  render() {
    const {
      classes,
      campaignList = [],
      metadata = {},
      requestCampaignListSend = true,
      canCreateCampaign,
      canUpdateCampaign
    } = this.props;
    const { page } = parseQuerystring(this.props) || {};

    return (
      <div>
        <div className={classes.titleRootWrapper}>
          <h1 className={classnames(classes.headline4, classes.title)}>
            {'Campañas'}
          </h1>
          {canCreateCampaign && (
            <Button
              className={classes.button}
              classes={{
                label: classes.buttonLabel
              }}
              variant="contained"
              color="secondary"
              onClick={this._handleOpenCreateCampaignModal}
            >
              {'Crear Campaña'}
            </Button>
          )}
        </div>
        <div>
          <div className={classes.filterOptionsWrapper}>
            <Filters
              onChangeFilter={this._fetchItems}
              suppliers={this.props.suppliers}
              categories={this.props.categories}
              geographicLocations={this.props.geographicLocations}
              saleChannels={this.props.saleChannels}
              contractStrata={this.props.contractStrata}
            />
          </div>
        </div>
        <div>
          <Table
            name={'Campaña'}
            columns={columns}
            data={mapData(campaignList)}
            onChangePage={this._handleChangePage}
            onChangePageRowsPerPage={this._handleChangeRowsPerPage}
            page={page - 1}
            rowsPerPage={this.state.rowsPerPage}
            onClickOnRow={props =>
              this._handleRowClick(props, canUpdateCampaign)
            }
            totalCount={(metadata && metadata.totalCount) || 0}
            loading={requestCampaignListSend}
          />
        </div>
        {this.state.isOpenedCreateCampaignModal && (
          <CampaignFormModal
            suppliers={this.props.suppliers}
            categories={this.props.categories}
            geographicLocations={this.props.geographicLocations}
            saleChannels={this.props.saleChannels}
            contractStrata={this.props.contractStrata}
            onClose={this._handleCloseCreateCampaignModal}
            onSave={this._handleSave}
            onDelete={this._handleDelete}
            errors={this.props.requestErrorsOnSaveCampaign}
            isSubmitting={this.props.requestSaveCampaignSend}
            isDeleting={this.props.requestDeleteCampaignSend}
            campaign={this.state.campaign}
          />
        )}
      </div>
    );
  }
}

const styles = (/* theme */) => ({
  actionBar: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    zIndex: 0
  }
});

CampaignList.propTypes = {
  metadata: PropTypes.object,
  campaignList: PropTypes.array.isRequired,
  suppliers: PropTypes.array,
  canCreateCampaign: PropTypes.bool,
  canUpdateCampaign: PropTypes.bool
};

export default withStyles(
  combineStyles(
    LayoutStyles,
    ButtonStyles,
    TypographyStyles,
    SpacingStyles,
    ListsStyle,
    styles
  )
)(CampaignList);
