/* eslint-disable complexity */
import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { Formik, Form } from 'formik';
import {
  validationSchema,
  validationSchemaAdditionalData,
  validationSchemaAdditionalDataOTP,
  validationSchemaCause
} from './PortalIDCheckValidation';
import { withStyles } from '@material-ui/core/styles';
import OutlinedTextField from '../../shared/Fields/OutlinedTextField';
import SegmentHeader from '../../shared/SegmentHeader';
import StepperButtons from '../../shared/StepperButtons';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogActions from '@material-ui/core/DialogActions';
import MuiDialogContent from '@material-ui/core/DialogContent';
import InfoField from '../../shared/InfoField';
import Dialog from '@material-ui/core/Dialog';
import PrimaryButton from '../../shared/Buttons/PrimaryButton';
import _get from 'lodash/get';
import OutlinedSelectChippedInput from '../../shared/Inputs/OutlinedSelectChipped';
import FormItemContainer from '../../shared/Containers/FormItemContainer';
import CustomDialog from '../../shared/Modals/CustomDialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import OTPValidationProcess from './OTPModal';
import { Fade, Typography } from '@material-ui/core';

class PortalIDCheck extends PureComponent {
  currentStep = this.props.routes.indexOf(this.props.location.pathname);
  nextStep = this.currentStep + 1;

  state = {
    externalTabOpened: false,
    biometric_identification: null,
    value1: 1,
    value2: 1,
    value3: 1,
    value4: 1,
    biometricValidationFailedTimes: 0,
    securityForm: false,
    securityQuestions: false,
    cause: '',
    observation: '',
    scrollUp: false,
    dataNeeded: false,
    biometricFailModal: false,
    noTriesRemaining: false,
    showOtpValidationModal: false,
    email: '',
    mobile: '',
    showAniUnavailableModal: false
  };

  componentDidMount() {
    const { isSecureSale, isBiometricValidationInSecureSaleActive } = this.props;
    const mandatoryBiometricValidation = isSecureSale && isBiometricValidationInSecureSaleActive;
    this.props.setCurrentStep(this.currentStep);
    this.props.toggleIDModal(false);
    this.props.resetBiometricProps(false);
    this.props.resetOTPCodeProp();

    if (!this.props.validateAniInSales) {
      this.setState({ showAniUnavailableModal: true });
      return;
    }

    const currentPOS =
      this.props.pointOfSales &&
      this.props.pointOfSales.find(i => i.id == this.props.pointOfSale);
    if (
      (!this._isTraditionalSale() || !this.ignoreBiometricValidation()) &&
      (currentPOS &&
        currentPOS.attributes.methodValidation === 'datacredito' &&
        currentPOS.attributes.hasSecurityQuestions) && !mandatoryBiometricValidation) {
      this.setState({ dataNeeded: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.validateAniInSales) return;

    if (this.props.causal !== prevProps.causal) {
      if (this.props.causal === true) {
        this.setState({ cause: '', observation: '', dataNeeded: true });
        this._handleClose();
      }
    }

    const mandatoryBiometricValidation = this.props.isSecureSale && this.props.isBiometricValidationInSecureSaleActive;
    if (
      this.props.debtorQuestions.securityQuestions !==
      prevProps.debtorQuestions.securityQuestions ||
      this.props.cosignerQuestions.securityQuestions !==
      prevProps.cosignerQuestions.securityQuestions
    ) {
      this.setState({ securityForm: true });
      this.setState({ securityQuestions: true });
    }
    if (
      this.props.biometricValidationFailedTimes !==
      prevProps.biometricValidationFailedTimes
    ) {
      this.setState({
        biometricValidationFailedTimes: this.props
          .biometricValidationFailedTimes
      });
      if (this.props.biometricValidationFailedTimes === 5) {
        this.setState({ biometricFailModal: true, noTriesRemaining: true });
      }
    }
    if (this.state.scrollUp) {
      this.setState({ scrollUp: false });
    }
    if (
      this.props.currentClient !== prevProps.currentClient ||
      this.props.scrollUp
    ) {
      if (this.props.currentClient == 'cosigner') {
        this.setState({ dataNeeded: true });
      }
      this.setState({ scrollUp: true });
      this.props.resetScrollProp();
    }

    if (this.props.questionsFailed) {
      this.props.resetProp({ causal: false, questionsFailed: false });
      this.setState({ securityForm: false, securityQuestions: false, cause: '', dataNeeded: true });
    }

    if (this.props.retry && !mandatoryBiometricValidation) {
      this.props.resetRetryProp();
      this.setState({ dataNeeded: true });
    }

    // OTP
    this.handleOTPCodes(prevProps);
  }

  handleOTPCodes = prevProps => {
    const identityOTP = this.props.identityOTP;
    if (
      prevProps.identityOTP.usingCrosscoreParams !==
      identityOTP.usingCrosscoreParams &&
      !identityOTP.usingCrosscoreParams
    ) {
      this.getSecurityQuestions(false);
      return;
    }

    if (
      ['99', '6'].includes(identityOTP.OTPCode) ||
      (identityOTP.OTPCode === '1' && identityOTP.requiresQuestions)
    ) {
      this.props.resetOTPCodeProp();
      this.setState({ showOtpValidationModal: false });
      this.onSubmitGetSecurityQuestions(true)({
        name: this.props.identityOTP.name,
        lastName: this.props.identityOTP.lastName
      });
    } else if (identityOTP.OTPCode === '1') {
      this.props.resetOTPCodeProp();
      this.setState({ showOtpValidationModal: false });
    } else if (identityOTP.OTPCode === '4') {
      this.setState(
        {
          showOtpValidationModal: true,
          dataNeeded: false
        },
        this.props.resetOTPCodeProp
      );
    }

    // Verify OTP
    if (identityOTP.OTPCode === '2') {
      this.setState(
        { showOtpValidationModal: false },
        this.props.resetOTPCodeProp
      );
      this.props.newAlert(
        'error',
        'OTP No Aprobado',
        'El OTP ingresado no fue aprobado. Intente de nuevo o realice validación biométrica.'
      );
    }
  };

  handleCloseOtpModal = () => {
    this.setState({ showOtpValidationModal: false });
  };

  getSecurityQuestions = (withRegValidation = true) => {
    this.setState({ dataNeeded: false });
    if (
      !this.props.pointOfSales ||
      (this._isTraditionalSale() && this.ignoreBiometricValidation())
    ) {
      return;
    }
    const currentPOS = this.props.pointOfSales.find(i => i.id == this.props.pointOfSale);

    let params = {
      contractId: this.props.debtor.contract.toString(),
      identification: this.props.debtor.buyerID,
      identificationType: this.props.debtor.buyerIDType,
      expeditionDate: this.props.debtor.expeditionDate,
      currentClient: 'debtor',
      methodValidation: currentPOS.attributes.methodValidation,
      name: this.state.name,
      lastName: this.state.lastName,
      regValidation: withRegValidation
        ? this.props.identityOTP.regValidation
        : null
    };

    if (this.props.currentClient == 'cosigner') {
      params = {
        contractId: this.props.debtor.contract.toString(),
        identification: this.props.cosigner.cosignerID,
        identificationType: this.props.cosigner.cosignerIDType,
        expeditionDate: this.props.cosigner.expeditionDate,
        currentClient: 'cosigner',
        methodValidation: currentPOS.attributes.methodValidation,
        name: this.state.name,
        lastName: this.state.lastName,
        regValidation: withRegValidation
          ? this.props.identityOTP.regValidation
          : null
      };
    }

    params['usingCrosscoreParams'] = this._isTraditionalSale()
      ? false
      : this.props.identityOTP.usingCrosscoreParams;

    this.props.getSecurityQuestions(
      params,
      this.props.routes[this.nextStep],
      this.props.process
    );
  };

  handleChangeRadio1 = event => {
    this.setState({ value1: event.target.value });
  };

  handleChangeRadio2 = event => {
    this.setState({ value2: event.target.value });
  };

  handleChangeRadio3 = event => {
    this.setState({ value3: event.target.value });
  };

  handleChangeRadio4 = event => {
    this.setState({ value4: event.target.value });
  };

  ignoreBiometricValidation = () => {
    const {
      saleChannel,
      channelIgnoreBiometricsValidation,
      debtor,
      cosigner
    } = this.props;

    return (
      (saleChannel &&
        channelIgnoreBiometricsValidation &&
        channelIgnoreBiometricsValidation.some(item => item == saleChannel)) ||
      (debtor.buyerIDType == 'Cédula de Extranjería' ||
        (cosigner && cosigner.cosignerIDType == 'Cédula de Extranjería'))
    );
  };
  onSubmit = () => {
    const currentPOS = this.props.pointOfSales.find(i => i.id == this.props.pointOfSale);
    const params = {
      contractId: this.props.debtor.contract.toString(),
      idQuestionnaire:
        this.props.currentClient == 'debtor'
          ? this.props.debtorQuestions.idQuestionnaire
          : this.props.cosignerQuestions.idQuestionnaire,
      regQuestionnaire:
        this.props.currentClient == 'debtor'
          ? this.props.debtorQuestions.regQuestionnaire
          : this.props.cosignerQuestions.regQuestionnaire,
      identification:
        this.props.currentClient == 'debtor'
          ? this.props.debtor.buyerID
          : this.props.cosigner.cosignerID,
      identificationType:
        this.props.currentClient == 'debtor'
          ? this.props.debtor.buyerIDType
          : this.props.cosigner.cosignerIDType,
      securityAnswers: [
        { questionId: '1', idAnswer: this.state.value1.slice(1, 3) },
        { questionId: '2', idAnswer: this.state.value2.slice(1, 3) },
        { questionId: '3', idAnswer: this.state.value3.slice(1, 3) },
        { questionId: '4', idAnswer: this.state.value4.slice(1, 3) }
      ],
      expeditionDate:
        this.props.currentClient == 'debtor'
          ? this.props.debtor.expeditionDate
          : '',
      cosigner: this.props.cosigner.cosignerID,
      currentClient: this.props.currentClient,
      name: this.state.name,
      lastName: this.state.lastName,
      methodValidation: currentPOS.attributes.methodValidation,
      usingCrosscoreParams: this._isTraditionalSale()
        ? false
        : this.props.identityOTP.usingCrosscoreParams
    };

    this.props.checkSecurityAnswers(
      params,
      this.props.routes[this.nextStep],
      this.props.process
    );
    this.setState({ value1: 1, value2: 1, value3: 1, value4: 1 });
  };

  onSubmitDatacreditoQuery = values => {
    this.props.checkFourthStep(
      values,
      this.props.debtor,
      this.props.cosigner,
      {
        dcDebtorLastName: values.debtorLastName,
        dcCosignerLastName: values.cosignerLastName
      },
      this.props.routes[this.nextStep],
      this.props.process
    );
  };

  startOtpProcess = values => {
    this.setState({
      name: values.name,
      lastName: values.lastName,
      dataNeeded: false
    });

    const isOnCosignerValidation = this.props.currentClient == 'cosigner';

    const person = isOnCosignerValidation
      ? this.props.cosigner
      : this.props.debtor;
    const params = isOnCosignerValidation
      ? {
        identification: person.cosignerID,
        identificationType: person.cosignerIDType,
        name: values.name,
        lastName: values.lastName,
        expeditionDate: person.expeditionDate,
        email: values.email,
        mobile: `${values.mobile}`
      }
      : {
        identification: person.buyerID,
        identificationType: person.buyerIDType,
        name: values.name,
        lastName: values.lastName,
        expeditionDate: person.expeditionDate,
        email: values.email,
        mobile: `${values.mobile}`
      };

    this.setState({ email: '', mobile: '' });
    this.props.startIdentityOtpTransaction(params);
  };

  onSubmitGetSecurityQuestions = (withRegValidation = false) => values => {
    this.setState({ name: values.name, lastName: values.lastName });
    this.getSecurityQuestions(withRegValidation);
  };

  onSubmitCloseBiometricFail = (mandatoryBiometricValidation) => {
    this.setState({ biometricFailModal: false });
    if (!mandatoryBiometricValidation) { this.setState({ dataNeeded: true }); }
  };

  onSubmitBiometricValidation = () => {
    this.props.checkBiometricValidation(
      {
        contractId: this.props.debtor.contract,
        identification: this.state.biometric_identification,
        biometricValidationFailedTimes: this.state
          .biometricValidationFailedTimes
      },
      this.props.routes[this.nextStep],
      this.props.process
    );
  };

  onCloseDialog = () => {
    this.props.toggleIDModal(false);
    this.props.toggleModal(false);
  };

  //Se podria eliminar
  onContinue = () => {
    const INITIAL_STEP = 0;
    this.props.toggleIDModal(false);
    if (
      !['NO ENCONTRADO', 'VIGENTE', 'SUSPENDIDA'].includes(
        this.props.debtor.status
      )
    ) {
      this.props.toggleBaseErrorModal(
        true,
        'El documento de identificación del deudor no se encuentra vigente.',
        'Registraduría',
        this.props.routes[INITIAL_STEP]
      );
    } else if (
      this.props.cosigner.cosignerID &&
      !['NO ENCONTRADO', 'VIGENTE', 'SUSPENDIDA'].includes(
        this.props.cosigner.dcStatus
      )
    ) {
      this.props.toggleBaseErrorModal(
        true,
        'El documento de identificación del codeudor no se encuentra vigente.',
        'Registraduría',
        this.props.routes[INITIAL_STEP]
      );
    } else {
      this.props.setNextStep(this.props.process);
      if (
        this.props.debtor.status === 'NO ENCONTRADO' ||
        this.props.cosigner.dcStatus === 'NO ENCONTRADO'
      ) {
        this.props.toggleBaseErrorModal(
          true,
          'El sistema no tiene registro de uno o ninguno de los usuarios ingresados. El registro de venta continuará pero es necesario anexar un soporte que garantice la validez de los documentos de identificación ingresados en el proceso; de lo contrario la venta no podrá ser aprobada.',
          'Registraduría',
          this.props.routes[this.nextStep]
        );
      } else if (
        this.props.cosigner.cosignerID &&
        (this.props.debtor.status == 'FALLECIDO' ||
          this.props.cosigner.dcStatus === 'FALLECIDO')
      ) {
        this.props.toggleBaseErrorModal(
          true,
          'El documento de identificación de uno de los usuarios corresponde a una persona fallecida.',
          'Registraduría',
          this.props.routes[this.nextStep]
        );
      } else {
        this.props.history.push(this.props.routes[this.nextStep]);
      }
    }
  };

  addQuestionMark = question => {
    return question.includes('?') ? `¿${question}` : question;
  };

  _handleClose = () => {
    this.setState({ questionModal: false });
    this.props.toggleBaseErrorModal(false);
  };

  _handleCloseAdditionalData = () => {
    this.setState(
      {
        dataNeeded: false
      },
      this.props.resetProp({
        causal: false
      })
    );
  };

  _handleConfirm = setFieldValue => {
    this.props.chooseCausal(
      {
        contractId: this.props.debtor.contract,
        saleId: null,
        causalId: this.state.cause,
        debtorClientId: parseInt(this.props.debtor.buyerID),
        cosignerClientId: parseInt(this.props.cosigner.cosignerID)
      },
      this.props.routes[this.nextStep],
      this.props.process,
      setFieldValue
    );
  };

  _handleSelectChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  _isRemoteDigitalSale = () => {
    return (
      this.props.process === 'remoteDigitalSaleWithUUP' ||
      this.props.process === 'remoteDigitalSaleWithoutUUP'
    );
  };

  _isTraditionalSale = () => {
    return this.props.process === 'portal';
  };

  renderCausals() {
    const { classes, isSecureSale, isBiometricValidationInSecureSaleActive } = this.props;
    const initialValuesCause = {
      cause: '',
      description: ''
    };

    const mandatoryBiometricValidation = isSecureSale && isBiometricValidationInSecureSaleActive;
    return (
      <Formik
        initialValues={initialValuesCause}
        validationSchema={validationSchemaCause}
        render={({ values, errors, touched, handleChange, setFieldValue }) => (
          <CustomDialog
            open={this.state.questionModal}
            disableBackdropClick={true}
            onClose={this._handleClose}
            onConfirm={() => !mandatoryBiometricValidation ? this._handleConfirm(setFieldValue) : this._handleClose()}
            title={!mandatoryBiometricValidation ? `Pasar a otro método de validación` : `Mensaje importante`}
            contentText={!mandatoryBiometricValidation ? `Para poder pasar a otro método de validación, debe escoger un causal. Además, si lo desea, podrá añadir una observación.` : ''}
            buttonText={'CONTINUAR'}
          >
            {!mandatoryBiometricValidation ? (
              <Form className={classes.formCause}>
                <Grid container>
                  <Fade in={true}>
                    <Grid item xs={12}>
                      <OutlinedSelectChippedInput
                        label="Causal:"
                        required
                        name="cause"
                        options={this.props.securityQuestionsCausals.map(
                          causal => ({
                            value: causal.id,
                            title: causal.description
                          })
                        )}
                        value={this.state.cause || values.cause}
                        onChange={e => {
                          handleChange(e);
                          this._handleSelectChange(e);
                        }}
                        withChip={false}
                        error={touched.cause && !!errors.cause}
                        helperText={touched.cause && errors.cause}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                      />
                    </Grid>
                  </Fade>
                  <Grid item xs={12}>
                    <TextField
                      label="Observación:"
                      name="observation"
                      margin="normal"
                      variant="outlined"
                      onChange={e => {
                        handleChange(e);
                        this._handleSelectChange(e);
                      }}
                      inputProps={{ maxLength: 200 }}
                      value={this.state.observation || values.description}
                      fullWidth
                      multiline
                    />
                    <p className={classes.observation}>{`${this.state.observation.length
                      } / 200`}</p>
                  </Grid>
                </Grid>
              </Form>
            ) : (
              <p>
                Las preguntas reto no se encuentran disponibles en una venta con fianza
              </p>
            )}
          </CustomDialog>
        )}
      />
    );
  }

  // eslint-disable-next-line complexity
  render() {
    const {
      classes,
      debtor,
      cosigner,
      debtorServerErrors = {},
      cosignerServerErrors = {},
      loading,
      idModal,
      promissory,
      transfer,
      cosignerIdentification,
      pointOfSale,
      pointOfSales,
      isSecureSale,
      debtorQuestions,
      isBiometricValidationInSecureSaleActive,
      identityOTP,
      validateIdentityWithCrosscore,
      currentClient,
      process,
      routes
    } = this.props;

    const mandatoryBiometricValidation = isSecureSale && isBiometricValidationInSecureSaleActive;
    const currentPOS = pointOfSales && pointOfSales.find(i => i.id == pointOfSale);
    const biometricValidationURL = currentPOS && currentPOS.attributes.biometricApiUrl;

    if (
      (!this._isTraditionalSale() || !this.ignoreBiometricValidation() || mandatoryBiometricValidation) &&
      biometricValidationURL &&
      !this.state.externalTabOpened &&
      currentPOS &&
      currentPOS.attributes.hasFingerprintBiometric
    ) {
      window.open(biometricValidationURL, '_blank');
      this.setState({ externalTabOpened: true });
    }
    const initialValues = {
      debtorLastName: debtor.dcDebtorLastName || '',
      debtorDocType: debtor.buyerIDType,
      debtorID: debtor.buyerID,
      cosignerLastName: transfer.contract
        ? ''
        : cosigner.cosignerID
          ? cosigner.dcCosignerLastName || ''
          : '',
      cosignerDocType: transfer.identificationType || cosigner.cosignerIDType,
      cosignerID: transfer.identification || cosigner.cosignerID
    };

    const initialValuesAdditionData = {
      lastName:
        this.props.currentClient == 'debtor'
          ? debtor.securityQuestionsLastName
            ? debtor.securityQuestionsLastName
            : ''
          : cosigner.securityQuestionsLastName
            ? cosigner.securityQuestionsLastName
            : '',
      name:
        this.props.currentClient == 'debtor'
          ? debtor.securityQuestionsName
            ? debtor.securityQuestionsName
            : ''
          : cosigner.securityQuestionsName
            ? cosigner.securityQuestionsName
            : '',
      email: '',
      mobile: ''
    };
    const isOnDebtorValidation = !debtor.biometricValidated || ((!promissory || !promissory.cosigner) && !initialValues.cosignerID);

    if (isOnDebtorValidation) {
      this.setState({ biometric_identification: debtor.buyerID });
    } else {
      this.setState({ biometric_identification: initialValues.cosignerID });
    }

    const hasFingerprintBiometric = currentPOS && currentPOS.attributes.hasFingerprintBiometric;
    const biometricEnabled = hasFingerprintBiometric && ['fixed_biometric', 'mobile_biometric'].includes(currentPOS.attributes.methodValidation) || mandatoryBiometricValidation;

    return (
      <div>
        <Paper className={classes.container}>
          {(!this._isTraditionalSale() || !this.ignoreBiometricValidation()) &&
            currentPOS && (currentPOS.attributes.hasSecurityQuestions || biometricEnabled) || mandatoryBiometricValidation ? (
            <>
              {(currentPOS.attributes.methodValidation == 'datacredito' || this.state.securityForm) && !mandatoryBiometricValidation && (
                <div>
                  <Formik
                    initialValues={initialValues}
                    onSubmit={this.onSubmit}
                    render={({
                      values,
                      errors,
                      handleChange,
                      handleSubmit,
                      touched
                    }) => (
                      <Form className={classes.form}>
                        <React.Fragment>
                          <SegmentHeader className={classes.segmentHeader}>
                            PREGUNTAS DE SEGURIDAD{' '}
                            {this.props.currentClient == 'cosigner'
                              ? 'CODEUDOR - '
                              : 'DEUDOR - '}
                            {this.state.name} {this.state.lastName}
                          </SegmentHeader>
                          <Grid container spacing={24} alignItems="flex-start">
                            <div style={{ padding: '12px', width: '100%' }}>
                              {this.state.securityQuestions &&
                                debtorQuestions.securityQuestions &&
                                this.props.currentClient == 'debtor' && (
                                  <React.Fragment>
                                    {this.state.scrollUp && (
                                      <input
                                        ref={input => input && input.focus()}
                                        defaultValue=""
                                      />
                                    )}
                                    <FormItemContainer
                                      title={this.addQuestionMark(
                                        this.props.debtorQuestions
                                          .securityQuestions[0].texto
                                      )}
                                      classes={{
                                        container: classes.container
                                      }}
                                      mediumSize={true}
                                    >
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          aria-label="gender"
                                          name="gender1"
                                          value={this.state.value1}
                                          onChange={this.handleChangeRadio1}
                                        >
                                          {this.props.debtorQuestions.securityQuestions[0].respuesta.map(
                                            respuesta => (
                                              <FormControlLabel
                                                value={respuesta.id}
                                                control={<Radio />}
                                                label={respuesta.texto}
                                                key={respuesta.id}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </FormItemContainer>
                                    <FormItemContainer
                                      title={this.addQuestionMark(
                                        this.props.debtorQuestions
                                          .securityQuestions[1].texto
                                      )}
                                      classes={{
                                        container: classes.container
                                      }}
                                      mediumSize={true}
                                    >
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          aria-label="gender"
                                          name="gender1"
                                          value={this.state.value2}
                                          onChange={this.handleChangeRadio2}
                                        >
                                          {this.props.debtorQuestions.securityQuestions[1].respuesta.map(
                                            respuesta => (
                                              <FormControlLabel
                                                value={respuesta.id}
                                                control={<Radio />}
                                                label={respuesta.texto}
                                                key={respuesta.id}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </FormItemContainer>
                                    <FormItemContainer
                                      title={this.addQuestionMark(
                                        this.props.debtorQuestions
                                          .securityQuestions[2].texto
                                      )}
                                      classes={{
                                        container: classes.container
                                      }}
                                      mediumSize={true}
                                    >
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          aria-label="gender"
                                          name="gender1"
                                          value={this.state.value3}
                                          onChange={this.handleChangeRadio3}
                                        >
                                          {this.props.debtorQuestions.securityQuestions[2].respuesta.map(
                                            respuesta => (
                                              <FormControlLabel
                                                value={respuesta.id}
                                                control={<Radio />}
                                                label={respuesta.texto}
                                                key={respuesta.id}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </FormItemContainer>
                                    <FormItemContainer
                                      title={this.addQuestionMark(
                                        this.props.debtorQuestions
                                          .securityQuestions[3].texto
                                      )}
                                      classes={{
                                        container: classes.container
                                      }}
                                      mediumSize={true}
                                    >
                                      <FormControl component="fieldset">
                                        <RadioGroup
                                          aria-label="gender"
                                          name="gender1"
                                          value={this.state.value4}
                                          onChange={this.handleChangeRadio4}
                                        >
                                          {this.props.debtorQuestions.securityQuestions[3].respuesta.map(
                                            respuesta => (
                                              <FormControlLabel
                                                value={respuesta.id}
                                                control={<Radio />}
                                                label={respuesta.texto}
                                                key={respuesta.id}
                                              />
                                            )
                                          )}
                                        </RadioGroup>
                                      </FormControl>
                                    </FormItemContainer>
                                  </React.Fragment>
                                )}
                            </div>
                          </Grid>
                          {this.props.cosigner.cosignerID !== '' &&
                            this.state.securityQuestions &&
                            this.props.cosignerQuestions.securityQuestions &&
                            debtorQuestions.securityQuestions &&
                            this.props.currentClient == 'cosigner' && (
                              <Grid
                                container
                                spacing={24}
                                alignItems="flex-start"
                              >
                                <div style={{ padding: '12px' }}>
                                  {this.state.scrollUp && (
                                    <input
                                      ref={input => input && input.focus()}
                                      defaultValue=""
                                    />
                                  )}
                                  <FormItemContainer
                                    title={this.addQuestionMark(
                                      this.props.cosignerQuestions
                                        .securityQuestions[0].texto
                                    )}
                                    classes={{ container: classes.container }}
                                    mediumSize={true}
                                  >
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={this.state.value1}
                                        onChange={this.handleChangeRadio1}
                                      >
                                        {this.props.cosignerQuestions.securityQuestions[0].respuesta.map(
                                          respuesta => (
                                            <FormControlLabel
                                              value={respuesta.id}
                                              control={<Radio />}
                                              label={respuesta.texto}
                                              key={respuesta.id}
                                            />
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </FormItemContainer>
                                  <FormItemContainer
                                    title={this.addQuestionMark(
                                      this.props.cosignerQuestions
                                        .securityQuestions[1].texto
                                    )}
                                    classes={{ container: classes.container }}
                                    mediumSize={true}
                                  >
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={this.state.value2}
                                        onChange={this.handleChangeRadio2}
                                      >
                                        {this.props.cosignerQuestions.securityQuestions[1].respuesta.map(
                                          respuesta => (
                                            <FormControlLabel
                                              value={respuesta.id}
                                              control={<Radio />}
                                              label={respuesta.texto}
                                              key={respuesta.id}
                                            />
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </FormItemContainer>
                                  <FormItemContainer
                                    title={this.addQuestionMark(
                                      this.props.cosignerQuestions
                                        .securityQuestions[2].texto
                                    )}
                                    classes={{ container: classes.container }}
                                    mediumSize={true}
                                  >
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={this.state.value3}
                                        onChange={this.handleChangeRadio3}
                                      >
                                        {this.props.cosignerQuestions.securityQuestions[2].respuesta.map(
                                          respuesta => (
                                            <FormControlLabel
                                              value={respuesta.id}
                                              control={<Radio />}
                                              label={respuesta.texto}
                                              key={respuesta.id}
                                            />
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </FormItemContainer>
                                  <FormItemContainer
                                    title={this.addQuestionMark(
                                      this.props.cosignerQuestions
                                        .securityQuestions[3].texto
                                    )}
                                    classes={{ container: classes.container }}
                                    mediumSize={true}
                                  >
                                    <FormControl component="fieldset">
                                      <RadioGroup
                                        aria-label="gender"
                                        name="gender1"
                                        value={this.state.value4}
                                        onChange={this.handleChangeRadio4}
                                      >
                                        {this.props.cosignerQuestions.securityQuestions[3].respuesta.map(
                                          respuesta => (
                                            <FormControlLabel
                                              value={respuesta.id}
                                              control={<Radio />}
                                              label={respuesta.texto}
                                              key={respuesta.id}
                                            />
                                          )
                                        )}
                                      </RadioGroup>
                                    </FormControl>
                                  </FormItemContainer>
                                </div>
                              </Grid>
                            )}
                        </React.Fragment>
                        <StepperButtons
                          disabledNext={!debtorQuestions.securityQuestions || (mandatoryBiometricValidation && this.state.noTriesRemaining)}
                          onSubmit={handleSubmit}
                          customLoading={loading}
                          goBack={this.props.goBack}
                        />
                      </Form>
                    )}
                  />
                </div>
              )}
              {biometricEnabled && !this.state.securityForm && (
                <>
                  {isOnDebtorValidation ? (
                    <React.Fragment>
                      <div className={classes.segmentHeaderOptions}>
                        <SegmentHeader className={classes.segmentHeader}>
                          VALIDACIÓN BIOMÉTRICA DEUDOR - {debtor.buyerID}
                        </SegmentHeader>
                        {currentPOS.attributes.hasSecurityQuestions && <PrimaryButton
                          onClick={() => {
                            this.setState({ questionModal: true });
                          }}
                          className={classes.button}
                        >
                          Pasar a otro método de validación
                        </PrimaryButton>}
                      </div>
                      {this.renderCausals()}
                      <div className={classes.item} variant="caption">
                        {`El proceso de validación biométrica está siendo realizado por el usuario en este momento. Al
                      completar el proceso, favor continuar presionando `}
                        <span className={classes.headline}>
                          <a
                            className={classes.linkRef}
                            href={biometricValidationURL}
                            // eslint-disable-next-line react/jsx-no-target-blank
                            target="_blank"
                          >
                            Siguiente
                          </a>
                        </span>
                        {` en esta pantalla`}
                      </div>
                      <StepperButtons
                        disabledNext={mandatoryBiometricValidation && this.state.noTriesRemaining}
                        onSubmit={this.onSubmitBiometricValidation}
                        customLoading={loading}
                        goBack={this.props.goBack}
                      />
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <div className={classes.segmentHeaderOptions}>
                        <SegmentHeader className={classes.segmentHeader}>
                          VALIDACIÓN BIOMÉTRICA CODEUDOR - {cosigner.cosignerID}
                        </SegmentHeader>
                      </div>
                      <div className={classes.item} variant="caption">
                        {`El proceso de validación biométrica está siendo realizado por el usuario en este momento. Al
                        completar el proceso, favor continuar presionando `}
                        <span className={classes.headline}>Siguiente</span>
                        {` en esta pantalla`}
                      </div>
                      <StepperButtons
                        onSubmit={this.onSubmitBiometricValidation}
                        customLoading={loading}
                        goBack={this.props.goBack}
                      />
                    </React.Fragment>
                  )}
                </>
              )}
            </>
          ) : (
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema(!!cosignerIdentification)}
              onSubmit={this.onSubmitDatacreditoQuery}
              render={({
                values,
                errors,
                handleChange,
                handleSubmit,
                touched
              }) => (
                <Form className={classes.form}>
                  <SegmentHeader
                    className={`${classes.segmentHeader} ${classes.segmentHeaderMargin}`}
                  >
                    VALIDACIÓN REGISTRADURIA DEUDOR
                  </SegmentHeader>
                  <Grid container spacing={24} alignItems="flex-start">
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={12}
                      className={classes.gridItem}
                    >
                      <OutlinedTextField
                        name="debtorLastName"
                        label="Primer apellido del deudor:"
                        value={values.debtorLastName}
                        error={
                          !!debtorServerErrors.debtorLastName ||
                          (touched.debtorLastName && !!errors.debtorLastName)
                        }
                        helperText={
                          debtorServerErrors.debtorLastName ||
                          (touched.debtorLastName && errors.debtorLastName)
                        }
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={12}
                      className={classes.gridItem}
                    >
                      <OutlinedTextField
                        label="Tipo Identificación del deudor:"
                        value={values.debtorDocType}
                        disabled
                      />
                    </Grid>
                    <Grid
                      item
                      md={4}
                      sm={6}
                      xs={12}
                      className={classes.gridItem}
                    >
                      <OutlinedTextField
                        label="Identificación del deudor:"
                        value={values.debtorID}
                        disabled
                      />
                    </Grid>
                  </Grid>
                  {((promissory && promissory.cosigner) ||
                    values.cosignerID) && (
                      <React.Fragment>
                        <SegmentHeader
                          className={`${classes.segmentHeader} ${classes.segmentHeaderMargin}`}
                        >
                          VALIDACIÓN REGISTRADURIA CODEUDOR
                        </SegmentHeader>
                        <Grid container spacing={24} alignItems="flex-start">
                          <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            className={classes.gridItem}
                          >
                            <OutlinedTextField
                              name="cosignerLastName"
                              label="Primer apellido del codeudor:"
                              value={values.cosignerLastName}
                              error={
                                !!cosignerServerErrors.cosignerLastName ||
                                (touched.cosignerLastName &&
                                  !!errors.cosignerLastName)
                              }
                              helperText={
                                cosignerServerErrors.cosignerLastName ||
                                (touched.cosignerLastName &&
                                  errors.cosignerLastName)
                              }
                              onChange={handleChange}
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            className={classes.gridItem}
                          >
                            <OutlinedTextField
                              label="Tipo Identificación del codeudor:"
                              value={values.cosignerDocType}
                              disabled
                            />
                          </Grid>
                          <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12}
                            className={classes.gridItem}
                          >
                            <OutlinedTextField
                              label="Identificación del codeudor:"
                              value={values.cosignerID}
                              disabled
                            />
                          </Grid>
                        </Grid>
                      </React.Fragment>
                    )}
                  <StepperButtons
                    onSubmit={handleSubmit}
                    customLoading={loading}
                    goBack={this.props.goBack}
                  />
                </Form>
              )}
            />
          )}
        </Paper>
        {idModal && (
          <Dialog
            onClose={this.onCloseDialog}
            open={idModal}
            PaperProps={{
              className: cosigner.cosignerID
                ? classes.dialogWithCosigner
                : classes.dialog
            }}
          >
            <MuiDialogContent>
              <article className={classes.dataContainer}>
                <section
                  className={
                    cosigner.cosignerID
                      ? classes.debtorDataWithCosigner
                      : classes.debtorData
                  }
                >
                  <MuiDialogTitle>Deudor</MuiDialogTitle>
                  <InfoField label="TIPO DE IDENTIFICACION:">
                    {debtor && debtor.buyerIDType}
                  </InfoField>
                  <InfoField label="NUMERO:">
                    {debtor && debtor.docID.replace(/^0+/, '')}
                  </InfoField>
                  {debtor.buyerIDType != 'Cédula de Extranjería' && (
                    <InfoField label="DEPARTAMENTO:">
                      {debtor && debtor.department}
                    </InfoField>
                  )}
                  {debtor.buyerIDType != 'Cédula de Extranjería' && (
                    <InfoField label="CIUDAD:">
                      {debtor && debtor.locality}
                    </InfoField>
                  )}
                  {debtor.buyerIDType == 'Cédula de Extranjería' && (
                    <InfoField label="PAIS ORIGEN:">
                      {debtor && debtor.country}
                    </InfoField>
                  )}
                  <InfoField label="NOMBRE:">{debtor && debtor.name}</InfoField>
                  <InfoField label="PRIMER APELLIDO:">
                    {debtor && debtor.lastName}
                  </InfoField>
                  <InfoField label="SEGUNDO APELLIDO:">
                    {debtor && debtor.secondLastName}
                  </InfoField>
                  <InfoField label="GENERO:">{debtor.gender || '--'}</InfoField>
                  <InfoField label="FECHA EXPEDICION:">
                    {debtor && debtor.expeditionDate}
                  </InfoField>
                  <InfoField label="ESTADO:">{debtor.status || '--'}</InfoField>
                </section>
                {cosigner.cosignerID && (
                  <section className={classes.cosignerData}>
                    <MuiDialogTitle>Codeudor</MuiDialogTitle>
                    <InfoField label="TIPO DE IDENTIFICACION:">
                      {cosigner && cosigner.cosignerIDType}
                    </InfoField>
                    <InfoField label="NUMERO:">
                      {cosigner && cosigner.dcID.replace(/^0+/, '')}
                    </InfoField>
                    {cosigner.cosignerIDType != 'Cédula de Extranjería' && (
                      <InfoField label="DEPARTAMENTO:">
                        {cosigner && cosigner.dcDepartment}
                      </InfoField>
                    )}
                    {cosigner.cosignerIDType != 'Cédula de Extranjería' && (
                      <InfoField label="CIUDAD:">
                        {cosigner && cosigner.dcLocality}
                      </InfoField>
                    )}
                    {cosigner.cosignerIDType == 'Cédula de Extranjería' && (
                      <InfoField label="PAIS ORIGEN:">
                        {cosigner && cosigner.dcCountry}
                      </InfoField>
                    )}
                    <InfoField label="NOMBRE:">
                      {cosigner && cosigner.dcName}
                    </InfoField>
                    <InfoField label="PRIMER APELLIDO:">
                      {cosigner && cosigner.dcLastName}
                    </InfoField>
                    <InfoField label="SEGUNDO APELLIDO:">
                      {cosigner && cosigner.dcSecondLastName}
                    </InfoField>
                    <InfoField label="GENERO:">
                      {cosigner.dcGender || '--'}
                    </InfoField>
                    <InfoField label="FECHA EXPEDICION:">
                      {cosigner && cosigner.dcExpeditionDate}
                    </InfoField>
                    <InfoField label="ESTADO:">
                      {cosigner.dcStatus || '--'}
                    </InfoField>
                  </section>
                )}
              </article>
            </MuiDialogContent>
            <MuiDialogActions>
              <PrimaryButton
                className={classes.dialogButton}
                onClick={this.onContinue}
              >
                CONTINUAR
              </PrimaryButton>
            </MuiDialogActions>
          </Dialog>
        )}
        {this.state.dataNeeded && (
          <Formik
            initialValues={initialValuesAdditionData}
            validationSchema={
              this._isRemoteDigitalSale() && validateIdentityWithCrosscore
                ? validationSchemaAdditionalDataOTP()
                : validationSchemaAdditionalData()
            }
            onSubmit={
              this._isRemoteDigitalSale() && validateIdentityWithCrosscore
                ? this.startOtpProcess
                : this.onSubmitGetSecurityQuestions()
            }
            render={({
              values,
              errors,
              handleChange,
              handleSubmit,
              touched
            }) => (
              <CustomDialog
                open={this.state.dataNeeded}
                disableBackdropClick={true}
                onClose={this._handleCloseAdditionalData}
                onConfirm={handleSubmit}
                title={`Se requiere la siguiente información`}
                buttonText={'CONTINUAR'}
              >
                <Form className={classes.formAdditionalData}>
                  <SegmentHeader
                    className={`${classes.segmentHeader} ${classes.segmentHeaderMargin}`}
                  >
                    INFORMACIÓN{' '}
                    {this.props.currentClient == 'debtor'
                      ? 'DEUDOR'
                      : 'CODEUDOR'}
                  </SegmentHeader>
                  <Grid container spacing={24} alignItems="flex-start">
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.gridItem}
                    >
                      <OutlinedTextField
                        name="lastName"
                        label={
                          'Primer apellido del ' +
                          (this.props.currentClient == 'debtor'
                            ? 'deudor'
                            : 'codeudor') +
                          ':'
                        }
                        value={values.lastName}
                        error={
                          this.props.currentClient == 'debtor'
                            ? !!debtorServerErrors.debtorLastName ||
                            (touched.debtorLastName &&
                              !!errors.debtorLastName)
                            : !!cosignerServerErrors.cosignerLastName ||
                            (touched.cosignerLastName &&
                              !!errors.cosignerLastName)
                        }
                        helperText={
                          this.props.currentClient == 'debtor'
                            ? !!debtorServerErrors.debtorLastName ||
                            (touched.debtorLastName &&
                              !!errors.debtorLastName)
                            : !!cosignerServerErrors.cosignerLastName ||
                            (touched.cosignerLastName &&
                              !!errors.cosignerLastName)
                        }
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid
                      item
                      md={6}
                      sm={6}
                      xs={12}
                      className={classes.gridItem}
                    >
                      <OutlinedTextField
                        name="name"
                        label={
                          'Nombres del ' +
                          (this.props.currentClient == 'debtor'
                            ? 'deudor'
                            : 'codeudor') +
                          ':'
                        }
                        value={values.name}
                        error={
                          this.props.currentClient == 'debtor'
                            ? !!debtorServerErrors.debtorName ||
                            (touched.debtorName && !!errors.debtorName)
                            : !!cosignerServerErrors.cosignerName ||
                            (touched.cosignerName && !!errors.cosignerName)
                        }
                        helperText={
                          this.props.currentClient == 'debtor'
                            ? !!debtorServerErrors.debtorName ||
                            (touched.debtorName && !!errors.debtorName)
                            : !!cosignerServerErrors.cosignerName ||
                            (touched.cosignerName && !!errors.cosignerName)
                        }
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  {this._isRemoteDigitalSale() && validateIdentityWithCrosscore && (
                    <div>
                      <SegmentHeader
                        className={`${classes.segmentHeader} ${classes.segmentHeaderMargin}`}
                      >
                        INFORMACIÓN DE CONTACTO
                      </SegmentHeader>
                      <Grid container>
                        <Grid item xs={12}>
                          <TextField
                            label="Correo Electrónico:"
                            name="email"
                            margin="normal"
                            variant="outlined"
                            onChange={e => {
                              handleChange(e);
                              this._handleSelectChange(e);
                            }}
                            value={this.state.email}
                            error={touched.email && !!errors.email}
                            helperText={touched.email && errors.email}
                            required
                            fullWidth
                          />

                          <TextField
                            label="Teléfono Celular:"
                            name="mobile"
                            margin="normal"
                            variant="outlined"
                            onChange={e => {
                              handleChange(e);
                              this._handleSelectChange(e);
                            }}
                            value={this.state.mobile}
                            error={touched.mobile && !!errors.mobile}
                            helperText={touched.mobile && errors.mobile}
                            type={'number'}
                            onInput={e => {
                              e.target.value = Math.max(
                                0,
                                parseInt(e.target.value)
                              )
                                .toString()
                                .slice(0, 10);
                            }}
                            required
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </Form>
              </CustomDialog>
            )}
          />
        )}
        {this.state.biometricFailModal && (
          <Formik
            onSubmit={() => this.onSubmitCloseBiometricFail(mandatoryBiometricValidation)}
            render={({
              handleSubmit,
            }) => (
              <CustomDialog
                open={this.state.biometricFailModal}
                disableBackdropClick={true}
                onConfirm={handleSubmit}
                title={`Mensaje importante: Validación biométrica`}
                buttonText={'CONTINUAR'}
              >
                <p className={classes.biometricErrorModal}>
                  {mandatoryBiometricValidation ? "Se agotaron los intentos. No se puede continuar con la venta" : this.props.biometricError}
                </p>
              </CustomDialog>
            )}
          />
        )}
        <OTPValidationProcess
          open={this.state.showOtpValidationModal}
          identityOTP={identityOTP}
          email={this.props.identityOTP.email}
          mobile={this.props.identityOTP.mobile}
          identification={
            this.props.currentClient == 'cosigner'
              ? cosigner.cosignerID
              : debtor.buyerID
          }
          identificationType={
            this.props.currentClient == 'cosigner'
              ? cosigner.cosignerIDType
              : debtor.buyerIDType
          }
          currentClient={currentClient}
          cosigner={cosigner.cosignerID}
          route={routes[this.nextStep]}
          process={process}
          contractId={this.props.debtor.contract}
          verifyIdentityOtp={this.props.verifyIdentityOtp}
          handleCloseOtpModal={this.handleCloseOtpModal}
          classes={classes}
        />
        <CustomDialog
          open={this.state.showAniUnavailableModal}
          title={`No se puede continuar con la venta`}
          onClose={() => this.setState({ showAniUnavailableModal: false })}
          onConfirm={() => this.setState({ showAniUnavailableModal: false })}
          disableBackdropClick={false}
          buttonText={'Cerrar'}
        >
          <Typography variant="body1">
            El servicio de validación de vigencia de cédula no se encuentra
            disponible, por lo tanto no es posible continuar con la validación
            de identidad
          </Typography>
        </CustomDialog>
      </div>
    );
  }
}
const styles = theme => ({
  container: {},
  segmentHeaderOptions: {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: '#f5f5f5'
  },
  button: {
    margin: 2,
    padding: '8px'
  },
  segmentHeader: {
    marginTop: 3 * theme.spacing.unit,
    '&:first-of-type': {
      marginTop: 2
    }
  },
  segmentHeaderMargin: {
    marginBottom: 2 * theme.spacing.unit
  },
  gridItem: {
    paddingTop: '0 !important',
    paddingBottom: '12 !important'
  },
  table: {
    marginTop: 3 * theme.spacing.unit,
    marginBottom: 3 * theme.spacing.unit
  },
  actionBar: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end'
  },
  form: {
    display: 'flex',
    flexDirection: 'column'
  },
  formCause: {
    padding: '8px'
  },
  observation: {
    color: 'grey',
    fontSize: '.9em',
    margin: 0,
    padding: 0
  },
  mainButton: {
    alignSelf: 'flex-end',
    marginTop: 4 * theme.spacing.unit,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  registerDateContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: 2 * theme.spacing.unit
  },
  dialog: {
    width: '100%'
  },
  dialogWithCosigner: {
    width: '100%',
    maxWidth: 1200
  },
  dialogButton: {
    margin: 1 * theme.spacing.unit,
    marginTop: 0,
    paddingLeft: 8 * theme.spacing.unit,
    paddingRight: 8 * theme.spacing.unit
  },
  dataContainer: {
    display: 'flex',
    flexDirection: 'row'
  },
  debtorData: {
    flex: '100%'
  },
  debtorDataWithCosigner: {
    flex: '50%',
    borderRight: '1px solid black'
  },
  cosignerData: {
    flex: '50%'
  },
  item: {
    margin: 20,
    textAlign: 'center'
  },
  headline: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold'
  },
  formAdditionalData: {
    padding: 0
  },
  biometricErrorModal: {
    minHeight: '100px'
  },
  linkRef: {
    textDecoration: 'none',
    color: 'inherit'
  }
});

PortalIDCheck.propTypes = {
  classes: PropTypes.object.isRequired,
  debtor: PropTypes.object,
  history: PropTypes.object.isRequired,
  validateInformation: PropTypes.func.isRequired
};

export default withStyles(styles)(PortalIDCheck);
